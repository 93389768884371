var render = function render(){
  var _vm$form, _vm$form$createdAt, _vm$form$createdAt$to;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "title": _vm.title + ' 신청',
      "persistent": "",
      "width": "600"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', _vm._b({}, 'v-card', {
    loading: _vm.loading
  }, false), [_c('div', [_c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("상품주문일자 : " + _vm._s((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : (_vm$form$createdAt = _vm$form.createdAt) === null || _vm$form$createdAt === void 0 ? void 0 : (_vm$form$createdAt$to = _vm$form$createdAt.toDate) === null || _vm$form$createdAt$to === void 0 ? void 0 : _vm$form$createdAt$to.call(_vm$form$createdAt)))]), _c('div', {
    staticClass: "pb-8px"
  }, [_c('purchase-item', {
    attrs: {
      "value": _vm.form,
      "mode": "view",
      "withQuantity": ""
    }
  })], 1), _c('v-divider'), _vm.type != _vm.PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value ? [_c('page-section', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('tit-wrap-small', {
          attrs: {
            "title": _vm.title + '유형'
          }
        }), _c('v-radio-group', {
          attrs: {
            "row": "",
            "hide-details": ""
          },
          model: {
            value: _vm.claimStatus,
            callback: function ($$v) {
              _vm.claimStatus = $$v;
            },
            expression: "claimStatus"
          }
        }, [_c('v-radio', {
          attrs: {
            "label": "교환",
            "value": _vm.PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value
          }
        }), _c('v-radio', {
          attrs: {
            "label": "반품 후 환불",
            "value": _vm.PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 2390702314)
  })] : _vm._e(), _c('page-section', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('tit-wrap-small', {
          attrs: {
            "title": _vm.typeText + '사유'
          }
        }), _c('v-select', _vm._b({
          attrs: {
            "placeholder": `${_vm.title} 사유 선택해주세요`,
            "items": _vm.claimReasonItems
          },
          model: {
            value: _vm.form.claimReason,
            callback: function ($$v) {
              _vm.$set(_vm.form, "claimReason", $$v);
            },
            expression: "form.claimReason"
          }
        }, 'v-select', Object.assign({}, _vm.attrs_input, {
          loading: _vm.loading
        }), false))];
      },
      proxy: true
    }])
  }), _c('page-section', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('tit-wrap-small', {
          attrs: {
            "title": _vm.typeText + '상세'
          }
        }), _c('v-textarea', _vm._b({
          attrs: {
            "placeholder": `${_vm.title} 상세를 입력해주세요`
          },
          model: {
            value: _vm.form.claimReasonDetails,
            callback: function ($$v) {
              _vm.$set(_vm.form, "claimReasonDetails", $$v);
            },
            expression: "form.claimReasonDetails"
          }
        }, 'v-textarea', Object.assign({}, _vm.attrs_input, {
          loading: _vm.loading
        }), false))];
      },
      proxy: true
    }])
  })], 2), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("취소")])], 1), _c('v-col', [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " 신청 ")])], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }