var render = function render(){
  var _this$value;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('tit-wrap-line', {
    staticClass: "mb-n4px",
    attrs: {
      "noDivider": "",
      "title": "배송지 정보"
    }
  }, [(_this$value = this.value) !== null && _this$value !== void 0 && _this$value.editable ? _c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.mode != 'form',
      expression: "mode != 'form'"
    }],
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-small",
    attrs: {
      "value": _vm.mode == 'form',
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        _vm.mode == 'form' ? _vm.mode = 'view' : _vm.mode = 'form';
      }
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("변경하기")])], 1), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.mode == 'form',
      expression: "mode == 'form'"
    }],
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-small",
    attrs: {
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": _vm.init
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("취소하기")])], 1), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.mode == 'form',
      expression: "mode == 'form'"
    }],
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-small",
    attrs: {
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": _vm.update
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("저장하기")])], 1)], 1) : _vm._e()], 1), _c('vertical-form-table', _vm._b({
    attrs: {
      "dense": true
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    items: _vm.items,
    mode: _vm.mode
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }