var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('mypage-layout', {
    attrs: {
      "titleSpacingLg": "",
      "title": "정기배송"
    }
  }, [[_c('regular-order-head', _vm._g(_vm._b({
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, 'regular-order-head', {
    regularOrders: _vm.regularOrders
  }, false), {
    removeSelected: _vm.removeSelected,
    removeDisabled: _vm.removeDisabled
  })), _c('div', {
    staticClass: "border-t border-2 border-dark"
  }, _vm._l(_vm.regularOrders, function (regularOrder) {
    return _c('v-row', {
      key: regularOrder._id,
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "xl": ""
      }
    }, [_c('regular-order-item', _vm._g(_vm._b({
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v;
        },
        expression: "selected"
      }
    }, 'regular-order-item', {
      regularOrder,
      product: regularOrder.products[0]
    }, false), {
      update: _vm.update,
      updateSelected: _vm.updateSelected
    }))], 1)], 1);
  }), 1)]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }