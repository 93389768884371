var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('mypage-layout', {
    attrs: {
      "titleSpacingLg": "",
      "title": "배송지 관리"
    }
  }, [_vm.items.length ? [_c('div', {
    staticClass: "border-t border-2 border-dark"
  }, _vm._l(_vm.items, function (item) {
    return _c('v-card', _vm._b({
      key: item === null || item === void 0 ? void 0 : item._id
    }, 'v-card', {
      loading: _vm.loading
    }, false), [_c('div', {
      staticClass: "py-16px"
    }, [_c('v-row', {
      staticClass: "ma-n8px",
      attrs: {
        "align-md": "center"
      }
    }, [_c('v-col', {
      staticClass: "pa-8px"
    }, [_c('v-card', {
      attrs: {
        "color": "transparent"
      }
    }, [_c('div', {
      staticClass: "txt txt--sm txt--dark font-weight-medium"
    }, [_c('v-row', {
      staticClass: "row--xs",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " (" + _vm._s(item.name) + ") ")]), item.isDefault ? _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-chip', {
      attrs: {
        "x-small": "",
        "color": "primary"
      }
    }, [_vm._v("기본배송지")])], 1) : _vm._e()], 1)], 1), _c('div', {
      staticClass: "txt txt--xs txt--light mt-4px mt-md-8px"
    }, [_c('div', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.phone))]), _c('div', [_vm._v(_vm._s(item.address))])])])], 1), _c('v-col', {
      staticClass: "pa-8px",
      attrs: {
        "cols": "12",
        "md": "auto"
      }
    }, [_c('div', {
      staticClass: "w-md-150px"
    }, [_c('v-row', {
      staticClass: "row--xs"
    }, [_c('v-col', {
      staticClass: "mw-100 flex-grow-1 flex-shrink-0",
      attrs: {
        "cols": "4",
        "md": "12"
      }
    }, [_c('destination-form', {
      attrs: {
        "value": item
      },
      on: {
        "input": _vm.search
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var attrs = _ref.attrs,
            on = _ref.on;
          return [_c('v-btn', _vm._g(_vm._b({
            attrs: {
              "block": "",
              "outlined": "",
              "color": "primary"
            }
          }, 'v-btn', attrs, false), on), [_vm._v("수정")])];
        }
      }], null, true)
    })], 1), _c('v-col', {
      staticClass: "mw-100 flex-grow-1 flex-shrink-0",
      attrs: {
        "cols": "4",
        "md": "12"
      }
    }, [_c('v-btn', {
      attrs: {
        "block": "",
        "outlined": "",
        "color": "grey"
      },
      on: {
        "click": [function ($event) {
          $event.preventDefault();
        }, function ($event) {
          return _vm.remove(item);
        }]
      }
    }, [_vm._v("삭제")])], 1)], 1)], 1)])], 1)], 1), _c('v-divider')], 1);
  }), 1), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('destination-form', {
    on: {
      "input": _vm.search
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var attrs = _ref2.attrs,
          on = _ref2.on;
        return [_c('v-row', {
          staticClass: "row--sm",
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "6",
            "sm": "auto"
          }
        }, [_c('v-btn', _vm._g(_vm._b({
          staticClass: "v-size--xx-large w-100 min-w-sm-200px",
          attrs: {
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_vm._v("배송지 등록하기")])], 1)], 1)];
      }
    }], null, false, 3836417557)
  })], 1)] : [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "mb-18px mb-md-24px"
  }, [_c('v-icon', {
    attrs: {
      "size": "60px",
      "color": "primary"
    }
  }, [_vm._v("mdi-truck")])], 1), _c('div', {
    staticClass: "tit tit--sm"
  }, [_vm._v("등록된 배송지가 없습니다.")]), _c('div', {
    staticClass: "txt txt--sm mt-8px"
  }, [_vm._v("배송지를 등록해주세요.")]), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('destination-form', {
    on: {
      "input": _vm.search
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var attrs = _ref3.attrs,
          on = _ref3.on;
        return [_c('v-row', {
          staticClass: "row--sm",
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "6",
            "sm": "auto"
          }
        }, [_c('v-btn', _vm._g(_vm._b({
          staticClass: "v-size--xx-large w-100 min-w-sm-200px",
          attrs: {
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_vm._v("배송지 등록하기")])], 1)], 1)];
      }
    }])
  })], 1)]), _c('v-layout', {
    staticClass: "py-15",
    attrs: {
      "column": ""
    }
  }, [_c('v-card-actions')], 1)]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }