var render = function render(){
  var _vm$user, _vm$user2, _vm$user2$point, _vm$user2$point$forma;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "rounded": "",
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-18px pa-md-24px text-center"
  }, [_c('v-avatar', {
    staticClass: "mb-8px mb-md-12px",
    attrs: {
      "size": "60",
      "color": "white"
    }
  }, [_c('icon-funds')], 1), _c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium mb-4px"
  }, [_vm._v(_vm._s((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.name) + " 님의 현재 보유 적립금")]), _c('div', {
    staticClass: "font-size-18 font-size-lg-28 font-weight-bold"
  }, [_vm._v(_vm._s(((_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : (_vm$user2$point = _vm$user2.point) === null || _vm$user2$point === void 0 ? void 0 : (_vm$user2$point$forma = _vm$user2$point.format) === null || _vm$user2$point$forma === void 0 ? void 0 : _vm$user2$point$forma.call(_vm$user2$point)) || 0) + "원")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }