<template>
    <client-page v-if="ready">
        <mypage-layout title="결제수단">
            <page-section class="page-section--sm py-0">
                <template #containerImmersive>
                    <card v-model="card" @init="init" @next="$refs.cardInputForm.open()" @password="$refs.simplePaymentPassword.open()"/>
                </template>
            </page-section>
        </mypage-layout>

        <card-input-form v-model="cardInfo" ref="cardInputForm" @step3="$refs.simplePaymentPassword.open()"/>
        <simple-payment-password v-model="cardInfo" ref="simplePaymentPassword" @next="$refs.confirmSimplePaymentPassword.open()" @prev="$refs.cardInputForm.open()"/>
        <confirm-simple-payment-password v-model="cardInfo" ref="confirmSimplePaymentPassword" @next="createBillKey" @prev="$refs.simplePaymentPassword.open()"/>
        <card-success ref="cardSuccess"/>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import CryptoAES from "@/plugins/crypto-aes";

import Card from "@/components/client/mypage/card/card.vue";
import CardInputForm from "@/components/client/mypage/card/card-input-form.vue";
import CardSuccess from "@/components/client/mypage/card/card-success.vue";
import SimplePaymentPassword from "@/components/client/mypage/card/simple-payment-password.vue"
import ConfirmSimplePaymentPassword from "@/components/client/mypage/card/confirm-simple-payment-password.vue"
export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,

        Card,
        CardInputForm,
        CardSuccess,
        SimplePaymentPassword,
        ConfirmSimplePaymentPassword
    },
    data() {
        return {
            card: null,
            ready: false,
            cardInfo: {
                name: null,
                cardNo: null,
                expYear: null,
                expMonth: null,
                idNo: null,
                cardPw: null,
                simplePassword: null,
                confirmSimplePassword: null,
            },
        }
    },
    async mounted() {
        await this.init();
        this.cardInfo = {
            name: null,
            cardNo: null,
            expYear: null,
            expMonth: null,
            idNo: null,
            cardPw: null,
            simplePassword: null,
            confirmSimplePassword: null,
        }
    },
    methods: {
        async init() {
            let { card } = await api.v1.me.cards.get()
            this.card = card;
            this.ready = true
        },
        async createBillKey() {
            let cardInfo = {
                ...this.cardInfo,
                simplePassword: CryptoAES.encrypt(this.cardInfo.simplePassword),
                confirmSimplePassword: CryptoAES.encrypt(this.cardInfo.confirmSimplePassword),
            }

            if (this.card) {
                let { success } = await api.v1.me.cards.put({ ...cardInfo, _id: this.card._id })
                if (success) {
                    this.$refs.cardSuccess.open("card");
                    await this.init()
                }
            } else {
                let { success } = await api.v1.me.cards.post(cardInfo);
                if (success) {
                    this.$refs.cardSuccess.open("card");
                    await this.init()
                }
            }
        },
    }
};
</script>
