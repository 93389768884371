<template>
    <u-dialog v-model="shows" title="간편비밀번호등록" persistent width="400">
        <div class="txt txt--xs txt--dark font-weight-medium text-center pt-12px pt-md-0">등록한 간편비밀번호를 다시 입력해주세요.</div>
        <div class="pt-12px mx-auto" style="max-width: 328px">
            <v-otp-input v-model="value.confirmSimplePassword" />
        </div>

        <div class="btn-wrap">
            <v-row class="row--sm">
                <v-col>
                    <v-btn block outlined color="grey" class="v-size--xx-large" @click="prev">비밀번호 재등록</v-btn>
                </v-col>
                <v-col>
                    <v-btn block color="primary" class="v-size--xx-large" @click="next">확인</v-btn>
                </v-col>
            </v-row>
        </div>
    </u-dialog>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import ProductItem from "../product/product-item.vue";
import TitWrapSmall from "@/components/client/dumb/tit-wrap-small.vue";
import VerticalTable from "@/components/dumb/vertical-table.vue";
import ShopProductCarts from "../../shop/shop-product-carts.vue";
import ShopProductOptions from "../../shop/shop-product-options.vue";
import ShopProductSupplies from "../../shop/shop-product-supplies.vue";

export default {
    components: {
        PageSection,
        UDialog,
        ProductItem,
        TitWrapSmall,
        VerticalTable,
        ShopProductCarts,
        ShopProductOptions,
        ShopProductSupplies,
    },
    props: {
        value: { type: Object, default: {} },
    },
    data() {
        return {
            shows: false,

            cardNo1: null,
            cardNo2: null,
            cardNo3: null,
            cardNo4: null,

            cardPw1: null,
            cardPw2: null,
        };
    },
    methods: {
        open() {
            this.shows = true;
        },
        next() {
            if (this.validate()) {
                this.shows = false;
                this.$emit("next");
            }
        },
        prev() {
            this.shows = false;
            this.$emit("prev");
        },
        validate() {
            try {
                if (!this.value.confirmSimplePassword || this.value.confirmSimplePassword.length !== 6) throw new Error("간편 비밀번호를 입력해주세요");
                if (this.value.simplePassword !== this.value.confirmSimplePassword) throw new Error("동일한 비밀번호를 입력해주세요");
                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
    },
};
</script>
