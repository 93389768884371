<template>
    <v-sheet>
        <v-row align-md="center">
            <v-col cols="auto" class="pa-8px">
                <v-card>
                    <v-img v-bind="{ src }" width="48" height="48" class="grey lighten-5">
                        <template #placeholder>
                            <v-overlay absolute color="transparent">
                                <v-icon x-small color="grey lighten-4">mdi-image-broken-variant</v-icon>
                            </v-overlay>
                        </template>
                    </v-img>
                </v-card>
            </v-col>
            <v-col class="pa-8px">
                <div class="txt txt--sm txt--dark font-weight-medium">
                    <span> {{ product?.name || "-" }} </span>
                </div>
                <div class="txt txt--xs txt--dark ">
                    {{ option }}
                </div>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { initProduct } from "@/assets/variables";
export default {
    props: {
        product: { type: Object, default: initProduct },
        purchase: { type: Object, default: () => ({}) },
        purchaseName: { type: String, default: null },
        purchaseAmount: { type: [Number, String], default: null },
    },
    computed: {
        src() {
            return `/res/shop/products/${this.product?._id}/${this.product?.thumb}`;
        },
        option() {
            let option = this.purchaseName || "";
            if (this.purchaseAmount) option += ` / ${this.purchaseAmount}개`;
            return option;
        },
    },
};
</script>

<style></style>
