<template>
    <span> <slot name="activator" v-bind="{ attrs: { disabled }, on: { click } }" /> </span>
</template>

<script>
export default {
    props: {
        value: { type: Boolean, default: false }, // loading

        items: { type: Array, default: () => [] },

        filter: { type: Function, default: () => () => false },
        remove: { type: Function, default: () => async () => {} },
    },
    computed: {
        targets() {
            return this.items.filter(this.filter);
        },
        disabled() {
            return !this.targets.length;
        },
    },
    methods: {
        async click() {
            if (this.value) return;
            else this.$emit("input", true);
            try {
                for (let item of this.targets) {
                    await this.remove(item);
                }
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.$emit("input", false);
                this.$emit("search");
            }
        },
    },
};
</script>

<style></style>
