<template>
    <v-card tile v-bind="{ to }" v-on="{ click }">
        <div class="py-16px">
            <v-row align-md="center" class="ma-n8px">
                <v-col cols="auto" class="pa-8px">
                    <v-card rounded="lg">
                        <v-img v-bind="{ src }" width="100" height="100">
                            <v-fade-transition>
                                <v-overlay v-show="!src" absolute>
                                    <v-icon>mdi-image-broken</v-icon>
                                </v-overlay>
                            </v-fade-transition>
                        </v-img>
                    </v-card>
                </v-col>
                <v-col v-if="mode == 'list'" class="pa-8px">
                    <div class="txt txt--xs txt--light mb-4px mb-md-8px mb-lg-4px">
                        <v-row align="center" class="row--xs">
                            <v-col cols="12" sm="auto">
                                <span :class="statusText?.includes?.('배송완료') ? 'primary--text' : 'txt--dark'">{{ statusText }}</span>
                            </v-col>
                            <v-col cols="auto" class="d-none d-sm-flex d-md-none d-lg-flex">
                                <v-divider vertical class="h-12px" />
                            </v-col>
                            <v-col cols="12" sm="auto">
                                <span>상품주문번호 : {{ value?.purchaseNo }}</span>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="txt txt--sm txt--dark font-weight-medium mb-12px">{{ value?.product?.name }}</div>
                    <v-row align="center" class="row--xxs font-size-18 font-size-md-20">
                        <v-col cols="auto">
                            <strong class="d-inline-flex align-center"> {{ (value?.salePrice * value?.amount).format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                        </v-col>
                        <v-col cols="auto" class="d-flex">
                            <span class="txt txt--xs txt--dark">·</span>
                        </v-col>
                        <v-col cols="auto" class="d-flex">
                            <span class="txt txt--xs txt--dark">{{ value?.amount }}개</span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col v-if="mode == 'view'" class="pa-8px">
                    <div class="txt txt--xs txt--light mb-4px mb-md-8px">상품주문번호 : {{ value?.purchaseNo }}</div>
                    <div class="txt txt--sm txt--dark font-weight-medium mb-12px">{{ value?.product?.name }}</div>
                    <v-row class="row--xxs">
                        <v-col cols="12" sm="auto">
                            <v-chip x-small outlined color="grey">옵션</v-chip>
                        </v-col>
                        <v-col cols="12" sm="">
                            <span class="txt txt--xs">{{ option }}</span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col v-if="$slots['actions']" cols="12" md="auto" class="pa-8px">
                    <div class="w-md-150px">
                        <slot name="actions" />
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-divider v-if="mode == 'list'" />
    </v-card>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: () => ({}) }, // purchase
        mode: { type: String, default: "list" },
        withQuantity: { type: Boolean, default: false },
        isOrderListItem: { type: Boolean, default: false },
    },
    computed: {
        src() {
            let { _id, thumb } = this.value?.product || {};
            if (_id && thumb) return `/res/shop/products/${_id}/${thumb}`;
        },
        statusText() {
            return this.$getStatusText(this.value);
        },
        option() {
            let option = this.value?.name || "";
            if (this.withQuantity) option += ` / ${this.value?.amount}개`;
            return option;
        },
        to() {
            if (this.isOrderListItem) return;
            else return `/shop/products/${this.value?.product?._id}`;
        },
    },
    methods: {
        click() {
            if (this.isOrderListItem) this.$emit("click");
        },
    },
};
</script>

<style></style>
