<template>
    <v-tab-item :value="tab">
        <tit-wrap-line noDivider>
            <template slot="titleContents">
                <div class="d-flex flex-column flex-sm-row align-sm-center">
                    <span class="tit tit--xxs">비밀번호 입력</span>
                    <span class="txt txt--sm pl-sm-8px mt-4px mt-sm-0">개인정보 확인을 위해 비밀번호를 입력해 주세요.</span>
                </div>
            </template>
        </tit-wrap-line>
        <vertical-form-table v-model="form" v-bind="{ items }">
            <template slot="아이디"> {{ username }} </template>
        </vertical-form-table>
        <div class="btn-wrap btn-wrap--lg">
            <v-row justify="center" class="row--sm">
                <v-col cols="6" sm="auto">
                    <v-btn color="primary" class="v-size--xx-large w-100 min-w-sm-200px" v-bind="{ loading }" @click="verify">확인</v-btn>
                </v-col>
            </v-row>
        </div>
    </v-tab-item>
</template>

<script>
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import api from "@/api";
import TitWrapLine from "../../dumb/tit-wrap-line.vue";
import { attrs_input } from "@/assets/variables";
import CryptoAES from "@/plugins/crypto-aes";

const items = [
    {
        term: "아이디",
    },
    {
        term: "비밀번호",
        type: "password",
        key: "password",
        ...attrs_input,
    },
];

export default {
    components: {
        VerticalFormTable,
        TitWrapLine,
    },
    props: {
        tab: { type: String, default: "verify" },
    },
    data: () => ({
        form: {
            password: "",
        },

        items,
        loading: false,
    }),
    computed: {
        username() {
            return this.$store.state.user?.username;
        },
    },
    methods: {
        async verify() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { password } = this.form;
                password = CryptoAES.encrypt(password);
                await api.v1.me.verify({ password });
                this.$emit("next");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
