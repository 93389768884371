var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "title": "간편결제등록완료",
      "persistent": "",
      "width": "400"
    },
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs txt--dark font-weight-medium text-center py-20px py-md-0"
  }, [_vm._v("간편결제 등록이 완료되었습니다.")]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', [_vm.type === 'cart' ? _c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.next
    }
  }, [_vm._v("정기배송 신청하기")]) : _vm._e(), _vm.type === 'card' ? _c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("확인")]) : _vm._e()], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }