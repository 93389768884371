<template>
    <client-page>
        <mypage-layout titleSpacingLg title="회차정보">
            <page-section v-for="(order, index) in items" :key="order._id" :class="index != 0 ? 'page-section--sm pb-0' : 'page-section--sm py-0'">
                <template slot="containerImmersive">
                    <tit-wrap-line noDivider>
                        <template slot="titleContents">
                            <div class="d-flex flex-column flex-sm-row align-sm-center">
                                <div class="pr-sm-8px mb-4px mb-sm-0">
                                    <v-chip outlined color="primary">{{ items.length - index }}회차</v-chip>
                                </div>
                                <span class="tit tit--xxs">{{ `${order.purchases[0].product.name} 외 ${order.purchases.length - 1}개 정기배송` }} </span>
                                <span class="txt txt--sm pl-sm-8px mt-4px mt-sm-0">{{ $dayjs(order.createdAt).format("YYYY.MM.DD") }}</span>
                            </div>
                        </template>
                    </tit-wrap-line>

                    <div class="border-t border-2 border-dark">
                        <template v-for="purchase in order.purchases">
                            <purchase-item :key="purchase._id" :value="purchase" isOrderListItem @click="$refs[purchase._order][0].$data.shows = true"> </purchase-item>
                        </template>
                    </div>
                </template>
            </page-section>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import { PURCHASE_CLAIM_STATUSES } from "@/assets/variables";

import RegularOrderHead from "@/components/client/mypage/regular-order/regular-order-head.vue";
import RegularOrderItem from "@/components/client/mypage/regular-order/regular-order-item.vue";
import CardForm from "@/components/client/mypage/card/card-form.vue";
import CardInputForm from "@/components/client/mypage/card/card-input-form.vue";
import CardSuccess from "@/components/client/mypage/card/card-success.vue";
import SimplePaymentPassword from "@/components/client/mypage/card/simple-payment-password.vue";
import ConfirmSimplePaymentPassword from "@/components/client/mypage/card/confirm-simple-payment-password.vue";
import RegularOrderFoot from "@/components/client/mypage/regular-order/regular-order-foot.vue";
import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";

import OrderView from "@/components/client/mypage/order/order-view.vue";
import PurchaseItem from "@/components/client/mypage/purchase/purchase-item.vue";
import PurchaseStatus from "@/components/client/mypage/purchase/purchase-status.vue";
import OrderItemComplete from "@/components/client/mypage/order/order-item-complete.vue";
import PurchaseItemClaim from "@/components/client/mypage/purchase/purchase-item-claim.vue";
import PurchaseItemDelivery from "@/components/client/mypage/purchase/purchase-item-delivery.vue";
import PurchaseItemClaimReject from "@/components/client/mypage/purchase/purchase-item-claim-reject.vue";
import PurchaseItemClaimWithdraw from "@/components/client/mypage/purchase/purchase-item-claim-withdraw.vue";

export default {
    components: {
        RegularOrderHead,
        RegularOrderItem,
        CardForm,
        CardInputForm,
        CardSuccess,
        SimplePaymentPassword,
        ConfirmSimplePaymentPassword,
        RegularOrderFoot,
        ClientPage,
        MypageLayout,

        PageSection,
        TitWrapLine,
        OrderView,
        PurchaseItem,
        PurchaseStatus,
        OrderItemComplete,
        PurchaseItemClaim,
        PurchaseItemDelivery,
        PurchaseItemClaimReject,
        PurchaseItemClaimWithdraw,
    },
    props: {
        type: { type: String, default: "cart" },
    },
    data() {
        return {
            orders: [],
            loading: false,
            PURCHASE_CLAIM_STATUSES,
        };
    },
    computed: {
        items() {
            // return this.orders.map((order) => ({ ...order, purchases: [...(this.purchases || [])].filter((purchase) => order?._id == purchase?._order) }));
            return this.orders;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let { regularOrder } = await api.v1.shop.regularOrders.get({ _id: this.$route.params._order });
            this.orders = regularOrder.orders;
        },
    },
};
</script>
