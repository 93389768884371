<template>
    <u-dialog v-model="shows" title="결제수단 등록" persistent width="400">
        <div class="txt txt--sm txt--dark font-weight-medium text-center mb-12px">등록된 카드가 없습니다. 카드를 추가해주세요.</div>

        <v-card max-width="220" rounded="md" color="primary lighten-5" class="mx-auto overflow-hidden text-center" style="border: 2px dashed var(--v-primary-lighten4) !important" @click="next">
            <v-responsive :aspect-ratio="220 / 128" content-class="d-flex align-center justify-center">
                <div class="line-height-0">
                    <span class="material-icons-round font-size-38 primary--text"> add </span>
                    <div class="txt txt--sm txt--dark font-weight-medium">카드등록</div>
                </div>
            </v-responsive>
        </v-card>
    </u-dialog>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import ProductItem from "../product/product-item.vue";
import TitWrapSmall from "@/components/client/dumb/tit-wrap-small.vue";
import VerticalTable from "@/components/dumb/vertical-table.vue";
import ShopProductCarts from "../../shop/shop-product-carts.vue";
import ShopProductOptions from "../../shop/shop-product-options.vue";
import ShopProductSupplies from "../../shop/shop-product-supplies.vue";

export default {
    components: {
        PageSection,
        UDialog,
        ProductItem,
        TitWrapSmall,
        VerticalTable,
        ShopProductCarts,
        ShopProductOptions,
        ShopProductSupplies,
    },
    data() {
        return {
            shows: false,
        };
    },
    methods: {
        open() {
            this.shows = true;
        },
        next() {
            this.shows = false;
            this.$emit("step2");
        },
    },
};
</script>
