<template>
    <client-page>
        <mypage-layout title="구매후기">
            <div class="border-t border-2 border-dark">
                <template v-for="item in reviews">
                    <review-list-item :value="item" :key="item._id" v-on="{ search }" />
                </template>
            </div>

            <div class="pagination-wrap pagination-wrap--sm">
                <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
            </div>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ReviewListItem from "@/components/client/mypage/reviews/list/review-list-item.vue";

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,
        ReviewListItem,
    },
    data: () => ({
        reviews: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.reviews = [];
            this.summary.totalCount = 0;
            this.loading = false;

            this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, reviews } = await api.v1.me.reviews.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.reviews = reviews;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
