<template>
    <u-dialog v-if="shows" title="입금하실계좌 정보" width="400">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <vertical-form-table :value="order" v-bind="{ items }" mode="view"> </vertical-form-table>
    </u-dialog>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import banks from "@/plugins/shop-default-banks.json";

import UDialog from "@/components/client/dumb/u-dialog.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

export default {
    components: {
        UDialog,
        VerticalFormTable,
    },
    props: {
        order: { type: Object, default: () => {} },
    },
    data: () => ({
        setting: undefined,
    }),
    computed: {
        ...mapState(["user"]),
        shows() {
            return this.order?.paymentMethod == "account";
        },
        items() {
            const items = [];

            const code = this.order?.account?.code || this.setting?.shop?.accountBankCode;
            const name = banks.find((bank) => bank.code == code)?.name;
            const accountHolder = this.order?.account?.accountHolder || this.setting?.shop?.accountHolder;
            const accountNumber = this.order?.account?.accountNumber || this.setting?.shop?.accountNumber;
            const senderName = this?.order?.account?.senderName || this.user?.name || "";

            items.push({ term: "은행명", data: name });
            items.push({ term: "예금주명", data: accountHolder });
            items.push({ term: "계좌번호", data: accountNumber });
            items.push({ term: "입금자명", data: senderName });
            items.push({ term: "입금금액", key: "totalPrice", type: "currency", suffix: "원" });

            return items;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.setting = (await api.v1.setting.get())?.setting;
        },
    },
};
</script>
