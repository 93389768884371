var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    staticClass: "v-data-table--default v-data-table--tit v-data-table--no-hover",
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "disable-sort": "",
      "items-per-page": -1,
      "fixed-header": "",
      "mobile-breakpoint": "768"
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.purchase`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('purchase-item', {
          staticClass: "text-left",
          attrs: {
            "value": item,
            "mode": "view"
          }
        })];
      }
    }, {
      key: `item.defaultPrice`,
      fn: function (_ref3) {
        var _ref4, _ref4$format;
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s((_ref4 = (item === null || item === void 0 ? void 0 : item.salePrice) + (item === null || item === void 0 ? void 0 : item.discountPrice)) === null || _ref4 === void 0 ? void 0 : (_ref4$format = _ref4.format) === null || _ref4$format === void 0 ? void 0 : _ref4$format.call(_ref4)) + " ")];
      }
    }, {
      key: `item.statusText`,
      fn: function (_ref5) {
        var item = _ref5.item,
          value = _ref5.value;
        return [_c('v-layout', {
          attrs: {
            "justify-center": "",
            "align-center": ""
          }
        }, [_c('span', [_vm._v(_vm._s(value))]), _c('order-view-delivery', {
          attrs: {
            "purchase": item
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref6) {
              var attrs = _ref6.attrs,
                on = _ref6.on,
                shows = _ref6.shows;
              return [shows ? _c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": "",
                  "text": "",
                  "small": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-truck-outline")])], 1) : _vm._e()];
            }
          }], null, true)
        }), _c('order-view-claim', {
          attrs: {
            "purchase": item
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref7) {
              var attrs = _ref7.attrs,
                on = _ref7.on,
                shows = _ref7.shows;
              return [shows ? _c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": "",
                  "text": "",
                  "small": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-clipboard-alert-outline")])], 1) : _vm._e()];
            }
          }], null, true)
        })], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items,
    itemClass: _vm.itemClass
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }