var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "100%",
      "max-width": "400",
      "content-class": "rounded-0"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on,
          shows: _vm.shows
        })];
      }
    }], null, true)
  }, [_c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('v-card-title', [_vm._v("클레임정보")]), _c('vertical-form-table', _vm._b({
    attrs: {
      "value": _vm.purchase,
      "mode": "view"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }