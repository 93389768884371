<template>
    <u-dialog v-if="shows" title="배송정보" width="400">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, shows }" />
        </template>

        <vertical-form-table :value="purchase" v-bind="{ items }" mode="view">
            <template slot="송장번호">
                <template v-if="purchase?.delivery?.url">
                    <a :href="`${purchase.delivery.url}${purchase?.delivery?.number || ''}`" target="_blank"> {{ purchase?.delivery?.number }} </a>
                </template>
                <template v-else> {{ purchase?.delivery?.number || "-" }} </template>
            </template>
        </vertical-form-table>
    </u-dialog>
</template>

<script>
import UDialog from "@/components/client/dumb/u-dialog.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

export default {
    components: {
        UDialog,
        VerticalFormTable,
    },
    props: {
        purchase: { type: Object, default: () => {} },
    },
    computed: {
        items() {
            const items = [];

            items.push({ type: "text", term: "배송방법", key: "delivery.method" });
            items.push({ type: "text", term: "택배업체", key: "delivery.name" });
            items.push({ type: "text", term: "송장번호" });
            if (this.purchase?.shippedAt) items.push({ type: "datetime", term: "배송시작일", key: "shippedAt" });
            if (this.purchase?.deliveredAt) items.push({ type: "datetime", term: "배송완료일", key: "deliveredAt" });

            return items;
        },
        shows() {
            const { name, number, method } = this.purchase?.delivery || {};
            return [name, number, method].some((item) => item);
        },
    },
};
</script>
