var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "title": "결제수단 등록",
      "persistent": "",
      "width": "400"
    },
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium text-center mb-12px"
  }, [_vm._v("등록된 카드가 없습니다. 카드를 추가해주세요.")]), _c('v-card', {
    staticClass: "mx-auto overflow-hidden text-center",
    staticStyle: {
      "border": "2px dashed var(--v-primary-lighten4) !important"
    },
    attrs: {
      "max-width": "220",
      "rounded": "md",
      "color": "primary lighten-5"
    },
    on: {
      "click": _vm.next
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 220 / 128,
      "content-class": "d-flex align-center justify-center"
    }
  }, [_c('div', {
    staticClass: "line-height-0"
  }, [_c('span', {
    staticClass: "material-icons-round font-size-38 primary--text"
  }, [_vm._v(" add ")]), _c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("카드등록")])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }