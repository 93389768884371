var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "title": "간편비밀번호등록",
      "persistent": "",
      "width": "400"
    },
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs txt--dark font-weight-medium text-center pt-12px pt-md-0"
  }, [_vm._v("등록한 간편비밀번호를 다시 입력해주세요.")]), _c('div', {
    staticClass: "pt-12px mx-auto",
    staticStyle: {
      "max-width": "328px"
    }
  }, [_c('v-otp-input', {
    model: {
      value: _vm.value.confirmSimplePassword,
      callback: function ($$v) {
        _vm.$set(_vm.value, "confirmSimplePassword", $$v);
      },
      expression: "value.confirmSimplePassword"
    }
  })], 1), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": _vm.prev
    }
  }, [_vm._v("비밀번호 재등록")])], 1), _c('v-col', [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.next
    }
  }, [_vm._v("확인")])], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }