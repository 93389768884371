var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.shows ? _c('u-dialog', {
    attrs: {
      "title": "입금하실계좌 정보",
      "width": "400"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true)
  }, [_c('vertical-form-table', _vm._b({
    attrs: {
      "value": _vm.order,
      "mode": "view"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false))], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }