<template>
    <v-dialog v-model="shows" persistent width="560" maxWidth="100%">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }" class="d-flex flex-column">
            <v-card-title>
                {{ typeText }} 거절 사유 <v-spacer />
                <v-icon @click="close">mdi-close</v-icon>
            </v-card-title>
            <v-card-subtitle> 상품주문번호: {{ form?.purchaseNo }} | 상품주문일자 : {{ form?.createdAt?.toDate?.() }} </v-card-subtitle>
            <v-divider />
            <v-card-text>
                <purchase-item :value="form" mode="view" withQuantity />
                <v-divider />
                <v-textarea :value="form.feedbackMessage || `${typeText} 신청이 거절되었습니다`" v-bind="{ ...attrs_input, loading }" readonly class="mt-5" />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, initPurchase, PURCHASE_CLAIM_STATUSES } from "@/assets/variables";

import PurchaseItem from "./purchase-item.vue";

export default {
    components: {
        PurchaseItem,
    },
    props: {
        value: { type: Object, default: initPurchase }, // purchases
        type: { type: String, default: "cancel" },
    },
    data: () => ({
        form: initPurchase(),

        // ui
        shows: false,
        loading: false,

        attrs_input,
    }),
    computed: {
        typeText() {
            return {
                [PURCHASE_CLAIM_STATUSES.CANCEL_REJECTED.value]: "취소",
                [PURCHASE_CLAIM_STATUSES.RETURN_REJECTED.value]: "반품",
                [PURCHASE_CLAIM_STATUSES.EXCHANGE_REJECTED.value]: "교환",
            }[this.type];
        },
        title() {
            return {
                [PURCHASE_CLAIM_STATUSES.CANCEL_REJECTED.value]: "취소",
                [PURCHASE_CLAIM_STATUSES.RETURN_REJECTED.value]: "교환·반품",
                [PURCHASE_CLAIM_STATUSES.EXCHANGE_REJECTED.value]: "교환·반품",
            }[this.type];
        },
        claimReasonItems() {
            let items = [];

            items.push("단순변심");
            if (this.type != "cancel") items.push("상품불량");
            items.push("기타");

            return items;
        },
    },
    watch: {
        shows(shows) {
            if (shows) this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initPurchase({ ...this.value });
        },

        emit() {
            this.$emit("input", this.form);
        },

        close() {
            this.shows = false;
        },
    },
};
</script>

<style></style>
