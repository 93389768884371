<template>
    <v-row no-gutters>
        <v-col cols="12" xl="">
            <product-item v-bind="{ product, isSoldOut }" hideDivider isLink>
                <template #select>
                    <v-simple-checkbox :value="isSelected" class="d-inline-block" @click.prevent.stop.capture="toggle" />
                </template>
            </product-item>
        </v-col>
        <v-col cols="auto" class="d-none d-xl-block"> <v-divider vertical /> </v-col>
        <v-col cols="12" xl="4">
            <div class="px-xl-20px py-xl-16px">
                <v-row class="row--sm">
                    <v-col cols="12">
                        <v-row align="center" class="row--xs">
                            <v-col>
                                <v-select v-model="regularOrder.deliveryCycle" v-bind="{ ...attrs_select_common }" :items="deliveryCycleItems" item-text="text" item-value="value" class="mw-auto" />
                            </v-col>
                            <v-col cols="auto">
                                <v-btn color="grey" style="height: 40px !important" @click="$emit('update', { _id: regularOrder._id, deliveryCycle: regularOrder.deliveryCycle })">변경</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="d-none d-xl-block"> <v-divider /> </v-col>
                    <v-col cols="12">
                        <v-card tile :color="$vuetify.breakpoint.lgAndDown ? 'grey lighten-5' : 'transparent'">
                            <div class="pa-10px pa-xl-0">
                                <div class="txt txt--xs">다음정기배송일 : {{ regularOrder?.nextDeliveryDate }}</div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" class="d-none d-xl-block"> <v-divider /> </v-col>
                    <v-col cols="12">
                        <v-row align="center" class="row--xs">
                            <v-col>
                                <div class="txt txt--xs">{{ regularOrder?.count }}회차</div>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn outlined color="grey" class="v-size--xx-small" @click="$router.push(`/mypage/regular/orders/${regularOrder._id}`)">회차내역보기</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </v-col>
        <v-col cols="auto" class="d-none d-xl-block"> <v-divider vertical /> </v-col>
        <v-col cols="12" xl="2">
            <!-- S: PC -->
            <div class="d-none d-xl-flex align-center h-100 pa-12px px-xl-20px py-md-16px">
                <v-row class="row--xxs text-center">
                    <v-col cols="12">
                        <div class="txt txt--xs">상태</div>
                    </v-col>
                    <v-col cols="12">
                        <div class="font-size-16 font-size-md-20 font-weight-bold grey--text text--darken-4">{{ displayStatus(regularOrder.status) }}</div>
                    </v-col>
                    <v-col cols="12">
                        <div class="mt-8px">
                            <template v-if="regularOrder.status === 'ONGOING'">
                                <v-btn outlined color="primary" class="v-size--xx-small" @click="$emit('update', { _id: regularOrder._id, status: 'STOPPING' })">중지하기</v-btn>
                            </template>
                            <template v-else>
                                <v-btn outlined color="primary" class="v-size--xx-small" @click="$emit('update', { _id: regularOrder._id, status: 'ONGOING' })">신청하기</v-btn>
                            </template>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <!-- E: PC -->

            <!-- S: Mobile -->
            <div class="d-xl-none pt-8px pb-6px">
                <v-row no-gutters align="center" justify="space-between" class="txt txt--xs txt--dark font-weight-medium">
                    <v-col cols="auto"> 상태 </v-col>
                    <v-col cols="auto">
                        <v-row align="center" class="row--sm">
                            <v-col cols="auto">
                                <div class="font-size-16 font-size-md-20 font-weight-bold grey--text text--darken-4">{{ displayStatus(regularOrder.status) }}</div>
                            </v-col>
                            <v-col cols="auto">
                                <template v-if="regularOrder.status === 'ONGOING'">
                                    <v-btn outlined color="primary" class="v-size--xx-small" @click="$emit('update', { _id: regularOrder._id, status: 'STOPPING' })">중지하기</v-btn>
                                </template>
                                <template v-else>
                                    <v-btn outlined color="primary" class="v-size--xx-small" @click="$emit('update', { _id: regularOrder._id, status: 'ONGOING' })">신청하기</v-btn>
                                </template>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
            <!-- E: Mobile -->
        </v-col>
        <v-col cols="auto" class="d-none d-xl-block"> <v-divider vertical /> </v-col>
        <v-col cols="12" xl="2">
            <!-- S: PC -->
            <div class="d-none d-xl-flex align-center h-100 pa-12px px-xl-20px py-md-16px">
                <v-row class="row--xxs text-center">
                    <v-col cols="12">
                        <div class="txt txt--xs">상품금액</div>
                    </v-col>
                    <v-col cols="12">
                        <strong class="d-inline-flex align-center font-size-18 font-size-md-20">{{ regularOrder?.totalPrice?.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small></strong>
                    </v-col>
                    <v-col cols="12">
                        <div class="txt txt--xs txt--light">배송비 {{ regularOrder?.deliveryPrice?.format() }}원</div>
                    </v-col>
                </v-row>
            </div>
            <!-- E: PC -->

            <!-- S: Mobile -->
            <div class="d-xl-none pb-16px">
                <div>
                    <v-row no-gutters justify="space-between" class="txt txt--xs txt--dark font-weight-medium">
                        <v-col cols="auto"> 상품금액 </v-col>
                        <v-col cols="auto">
                            <strong>{{ regularOrder?.totalPrice?.format() }}원</strong>
                        </v-col>
                    </v-row>
                </div>
                <div class="mt-10px">
                    <v-row no-gutters justify="space-between" class="txt txt--xs">
                        <v-col cols="auto"> 배송비 </v-col>
                        <v-col cols="auto">
                            <span class="font-weight-medium">{{ regularOrder?.deliveryPrice?.format() }}원</span>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <!-- E: Mobile -->
        </v-col>
        <v-col cols="auto" class="d-none d-xl-block"> <v-divider vertical /> </v-col>
        <v-col cols="12"> <v-divider /> </v-col>
    </v-row>
</template>

<script>
import { attrs_select_common } from "@/assets/variables";

import LikeBtn from "../../like/like-btn.vue";
import RegularOrderForm from "./regular-order-form.vue";
import ProductItem from "../product/product-item.vue";

export default {
    components: {
        LikeBtn,
        RegularOrderForm,
        ProductItem,
    },
    props: {
        value: { type: Array, default: () => [] }, // selected
        regularOrder: { type: Object, default: () => ({}) },
        product: { type: Object, default: () => ({}) },
    },
    data() {
        return {
            attrs_select_common,

            deliveryCycleItems: [
                { text: "1주일", value: "1week" },
                { text: "2주일", value: "2week" },
                { text: "1달", value: "1month" },
                { text: "3달", value: "3month" },
            ],
        };
    },
    computed: {
        isSoldOut() {
            return this.product?.soldout;
        },
        isSelected() {
            const _selected = this.value.map(({ _id }) => _id);
            return _selected.includes(this.regularOrder._id);
        },
    },
    methods: {
        toggle() {
            const _selected = this.value.map(({ _id }) => _id);
            const pushbales = _selected.includes(this.regularOrder._id);
            if (pushbales) {
                this.$emit(
                    "updateSelected",
                    this.value.filter((el) => el._id !== this.regularOrder._id)
                );
            } else {
                this.$emit("updateSelected", [...this.value, this.regularOrder]);
            }
        },
        displayStatus(status) {
            if (status === "ONGOING") {
                return "진행중";
            }
            return "중지중";
        },
    },
};
</script>

<style></style>
