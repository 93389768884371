var render = function render(){
  var _vm$form, _vm$form2, _vm$form2$createdAt, _vm$form2$createdAt$t;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "560",
      "maxWidth": "100%"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', _vm._b({
    staticClass: "d-flex flex-column"
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " 철회하기 "), _c('v-spacer'), _c('v-icon', {
    on: {
      "click": _vm.close
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-card-subtitle', [_vm._v(" 상품주문번호: " + _vm._s((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : _vm$form.purchaseNo) + " | 상품주문일자 : " + _vm._s((_vm$form2 = _vm.form) === null || _vm$form2 === void 0 ? void 0 : (_vm$form2$createdAt = _vm$form2.createdAt) === null || _vm$form2$createdAt === void 0 ? void 0 : (_vm$form2$createdAt$t = _vm$form2$createdAt.toDate) === null || _vm$form2$createdAt$t === void 0 ? void 0 : _vm$form2$createdAt$t.call(_vm$form2$createdAt)) + " ")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('purchase-item', {
    attrs: {
      "value": _vm.form,
      "mode": "view",
      "withQuantity": ""
    }
  }), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "px-0 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.typeText) + " 유형")]), _c('v-select', _vm._b({
    attrs: {
      "placeholder": `${_vm.title} 유형을 선택해주세요`,
      "items": _vm.claimReasonItems,
      "readonly": ""
    },
    model: {
      value: _vm.form.claimReason,
      callback: function ($$v) {
        _vm.$set(_vm.form, "claimReason", $$v);
      },
      expression: "form.claimReason"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false)), _c('v-card-subtitle', {
    staticClass: "px-0 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.typeText) + " 사유")]), _c('v-textarea', _vm._b({
    attrs: {
      "placeholder": `${_vm.title} 사유를 입력해주세요`,
      "readonly": ""
    },
    model: {
      value: _vm.form.claimReasonDetails,
      callback: function ($$v) {
        _vm.$set(_vm.form, "claimReasonDetails", $$v);
      },
      expression: "form.claimReasonDetails"
    }
  }, 'v-textarea', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-card-actions', {
    staticClass: "pa-3"
  }, [_c('v-col', [_c('v-btn', {
    attrs: {
      "outlined": "",
      "x-large": "",
      "block": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" 취소 ")])], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "x-large": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " 철회 ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }