<template>
    <v-card v-if="value" rounded color="grey lighten-5">
        <div class="pa-18px pa-md-24px">
            <div class="txt txt--sm txt--dark font-weight-medium text-center mb-12px">현재 등록된 카드</div>

            <v-card max-width="316" rounded="md" color="purple" class="mx-auto overflow-hidden">
                <v-responsive :aspect-ratio="316 / 186">
                    <div class="h-100 pa-14px">
                        <v-row no-gutters class="h-100">
                            <v-col>
                                <v-row no-gutters class="h-100">
                                    <v-col cols="12">
                                        <div class="font-size-16 font-weight-black white--text" style="opacity: 0.5">CREDIT CARD</div>
                                    </v-col>
                                    <v-col cols="12" align-self="end">
                                        <div class="txt font-weight-medium white--text">{{ value.name }}</div>
                                        <div class="txt font-weight-medium white--text">{{ `${value.cardNo.slice(0, 4)} ${value.cardNo.slice(4, 8)} ${value.cardNo.slice(8, 12)} ${value.cardNo.slice(12, 16)}` }}</div>
                                        <div class="txt font-weight-medium white--text">{{ `${value.expMonth}/${value.expYear}` }}</div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col align-self="center" cols="auto">
                                <v-img src="/images/common/card-chip.png" />
                            </v-col>
                        </v-row>
                    </div>
                </v-responsive>
            </v-card>

            <div class="pt-12px">
                <v-row justify="center" class="row--xs">
                    <v-col cols="auto">
                        <v-btn x-small outlined color="grey" @click="remove">카드삭제</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn x-small outlined color="grey" @click="update">카드변경</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn x-small color="primary" @click="$emit('password')">간편 비밀번호 변경</v-btn>
                    </v-col>
                </v-row>
            </div>
        </div>
    </v-card>

    <v-card v-else rounded color="grey lighten-5">
        <div class="pa-18px pa-md-24px">
            <div class="txt txt--sm txt--dark font-weight-medium text-center mb-12px">등록된 카드가 없습니다. 카드를 추가해주세요.</div>

            <v-card max-width="316" rounded="md" color="primary lighten-5" class="mx-auto overflow-hidden text-center" style="border: 2px dashed var(--v-primary-lighten4) !important" @click="next">
                <v-responsive :aspect-ratio="316 / 186" content-class="d-flex align-center justify-center">
                    <div class="line-height-0">
                        <span class="material-icons-round font-size-38 primary--text"> add </span>
                        <div class="txt txt--sm txt--dark font-weight-medium">카드등록</div>
                    </div>
                </v-responsive>
            </v-card>
        </div>
    </v-card>
</template>

<script>
import api from "@/api";

export default {
    props: {
        value: { type: Object, default: null },
    },
    methods: {
        async update() {
            if (confirm("카드를 변경하시면 현재 등록된 카드는 삭제됩니다\n카드변경을 진행하시겠습니까?")) {
                await api.v1.me.cards.delete({ _id: this.value._id });
                alert("카드가 삭제되었습니다");
                this.$emit("init");
                this.$emit("next");
            }
        },
        async remove() {
            if (confirm("현재 등록된 카드를 삭제하시겠습니까?")) {
                await api.v1.me.cards.delete({ _id: this.value._id });
                alert("카드가 삭제되었습니다");
                this.$emit("init");
            }
        },
        next() {
            this.$emit("next");
        },
    },
};
</script>

<style></style>
