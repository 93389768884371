<template>
    <client-page>
        <mypage-layout titleSpacingLg title="장바구니">
            <div class="tabs-wrap">
                <v-tabs grow>
                    <v-tab exact to="/mypage/cart/single">일반배송({{ singleCartTotalCount }})</v-tab>
                    <v-tab exact to="/mypage/cart/regular">정기배송({{ regularCartTotalCount }})</v-tab>
                </v-tabs>
            </div>

            <template v-if="carts.length">
                <cart-head v-model="selected" v-bind="{ carts, carts__outOfStock }" v-on="{ removeSelected, removeDisabled }" />

                <!-- S: cart-body -->
                <div class="border-t border-2 border-dark">
                    <v-row no-gutters v-for="{ shipping, deliveryPrice, deliveryMessage, groups } in cartMaps" :key="shipping.code">
                        <v-col cols="12" xl="">
                            <template v-for="item in groups">
                                <cart-item v-model="selected" v-bind="item" v-on="{ remove, search }" :key="item._id" />
                            </template>
                        </v-col>
                        <v-col cols="12" xl="2" class="d-none d-xl-block">
                            <div class="d-flex align-center h-100 border-b pa-12px px-xl-20px py-md-16px">
                                <v-row class="row--xxs text-center">
                                    <v-col cols="12">
                                        <div class="txt txt--xs">배송비</div>
                                    </v-col>
                                    <v-col cols="12">
                                        <strong class="d-inline-flex align-center font-size-18 font-size-md-20">
                                            {{ deliveryPrice?.format?.() }}<small class="font-size-14 font-weight-regular pl-2px">원</small>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" small color="grey lighten-3" class="ml-4px cursor-pointer">mdi-help-circle-outline</v-icon>
                                                </template>
                                                <span>{{ deliveryMessage }}</span>
                                            </v-tooltip>
                                        </strong>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <!-- E: cart-body -->

                <div class="pt-24px px-md-16px">
                    <v-card color="primary lighten-4" rounded>
                        <div class="px-12px py-16px pa-md-8px">
                            <v-row align="center" justify="center" class="row--sm">
                                <v-col cols="12" sm="auto">
                                    <div class="txt txt--xs font-weight-medium primary--text text--darken-4">배송주기</div>
                                </v-col>
                                <v-col cols="12" sm="auto">
                                    <v-select v-model="deliveryCycle" v-bind="{ ...attrs_input }" :items="deliveryCycleItems" :style="$vuetify.breakpoint.lgAndUp ? 'width: 500px' : ''" />
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </div>

                <div class="pt-24px px-md-16px">
                    <cart-foot v-bind="{ selected, regular: true }" v-on="{ order }" />
                </div>

                <!-- <div class="btn-wrap btn-wrap--lg">
                    <v-card rounded="" elevation="4">
                        <div class="pa-18px pa-md-24px">
                            <v-row align="center" justify="center">
                                <v-col cols="auto">
                                    <div class="d-inline-flex align-center">
                                        <div class="txt txt--xs pr-10px pr-md-16px">총 주문금액</div>
                                        <strong class="font-size-20 font-size-md-36 primary--text"> {{ totalPrice?.format?.() || 0 }}<small class="font-size-18 font-size-md-20 font-weight-regular pl-2px">원</small> </strong>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="auto">
                                    <v-btn x-large color="primary" class="v-size--xx-large w-100 min-w-sm-200px" @click="order(carts)">전체 주문하기</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </div> -->
            </template>

            <template v-else>
                <div class="text-center mt-20px mt-md-30px mt-lg-60px">
                    <div class="mb-18px mb-md-24px">
                        <v-icon size="60px" color="primary">mdi-cart</v-icon>
                    </div>
                    <div class="tit tit--sm">장바구니에 담긴 상품이 없습니다.</div>
                    <div class="txt txt--sm mt-8px">원하시는 상품을 담아주세요.</div>

                    <div class="btn-wrap btn-wrap--lg">
                        <v-row justify="center" class="row--sm">
                            <v-col cols="6" sm="auto">
                                <v-btn color="primary" to="/shop" class="v-size--xx-large w-100 min-w-sm-200px">쇼핑 계속하기</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </mypage-layout>
        <card-form ref="cardForm" @step2="$refs.cardInputForm.open()" />
        <card-input-form v-model="cardInfo" ref="cardInputForm" @step3="$refs.simplePaymentPassword.open()" />
        <simple-payment-password v-model="cardInfo" ref="simplePaymentPassword" @next="$refs.confirmSimplePaymentPassword.open()" @prev="$refs.cardInputForm.open()" />
        <confirm-simple-payment-password v-model="cardInfo" ref="confirmSimplePaymentPassword" @next="createBillKey" @prev="$refs.simplePaymentPassword.open()" />
        <card-success ref="cardSuccess" @next="order(selected)" />
    </client-page>
</template>

<script>
import api from "@/api";
import { mapMutations, mapState } from "vuex";
import CryptoAES from "@/plugins/crypto-aes";
import { attrs_input } from "@/assets/variables";

import CartHead from "@/components/client/mypage/cart/cart-head.vue";
import CartItem from "@/components/client/mypage/cart/cart-item.vue";
import CardForm from "@/components/client/mypage/card/card-form.vue";
import CardInputForm from "@/components/client/mypage/card/card-input-form.vue";
import CardSuccess from "@/components/client/mypage/card/card-success.vue";
import SimplePaymentPassword from "@/components/client/mypage/card/simple-payment-password.vue";
import ConfirmSimplePaymentPassword from "@/components/client/mypage/card/confirm-simple-payment-password.vue";
import CartFoot from "@/components/client/mypage/cart/cart-foot.vue";
import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";

export default {
    components: {
        CartHead,
        CartItem,
        CardForm,
        CardInputForm,
        CardSuccess,
        SimplePaymentPassword,
        ConfirmSimplePaymentPassword,
        CartFoot,
        ClientPage,
        MypageLayout,
    },
    props: {
        type: { type: String, default: "cart" },
    },
    data: () => ({
        attrs_input,

        carts: [],
        selected: [],
        deliveryType: "regular",
        deliveryCycle: "1week",
        deliveryCycleItems: [
            { text: "1주일", value: "1week" },
            { text: "2주일", value: "2week" },
            { text: "1달", value: "1month" },
            { text: "3달", value: "3month" },
        ],
        cardInfo: {
            name: null,
            cardNo: null,
            expYear: null,
            expMonth: null,
            idNo: null,
            cardPw: null,
            simplePassword: null,
            confirmSimplePassword: null,
        },
        loading: false,
        singleCartTotalCount: 0,
        regularCartTotalCount: 0,
    }),
    computed: {
        ...mapState(["accessToken", "shippings"]),
        cartMaps() {
            return this.$getCartMaps(this.carts).map((map) => {
                const carts = map.groups.flatMap(({ carts }) => carts);
                const deliveryPrice = this.$getDeliveryPrice(carts, this.shippings);
                return { ...map, deliveryPrice };
            });
        },
        productPirce() {
            return this.$getProductPrice(this.carts);
        },
        discountPirce() {
            return this.$getDiscountPrice(this.carts);
        },
        deliveryPrice() {
            return this.$getDeliveryPrice(this.carts, this.shippings);
        },
        totalPrice() {
            return this.productPirce - this.discountPirce + this.deliveryPrice;
        },
        carts__outOfStock() {
            return this.selected.filter(({ stock = 0 }) => stock <= 0);
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapMutations(["setCarts"]),
        async init() {
            try {
                this.selected = [];
                await this.search();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.selected = [...this.cartMaps.flatMap(({ groups }) => groups.flatMap(({ carts }) => carts))];
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const _carts = (this.$store?.state?.carts || []).map(({ _id }) => _id);
                const params = { _carts, deliveryType: this.deliveryType };

                if (this.accessToken) delete params._carts;

                let { carts, summary } = await api.v1.me.carts.gets({ params });
                this.carts = carts?.filter?.(({ product }) => product);
                this.singleCartTotalCount = summary.singleCartTotalCount;
                this.regularCartTotalCount = summary.regularCartTotalCount;

                this.setCarts({ carts: this.carts });
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
                this.selected = [...this.cartMaps.flatMap(({ groups }) => groups.flatMap(({ carts }) => carts))];
            }
        },

        async order(selected = []) {
            console.log(selected);
            try {
                if (!selected.length) throw new Error("구매하실 상품을 선택해주세요");
                if (!this.deliveryCycle) throw new Error("배송주기를 선택해주세요");
                let { card } = await api.v1.me.cards.get();
                if (!card) {
                    // 카드 등록
                    this.$refs.cardForm.open();
                } else {
                    const _carts = selected.map(({ _id }) => _id);
                    this.$store.dispatch("pickup", _carts);

                    this.$router.push(`/shop/order?deliveryType=regular&deliveryCycle=${this.deliveryCycle}`);
                }
            } catch (error) {
                this.$handleError(error);
            }
            // try {
            //     if (!selected.length) throw new Error("구매하실 상품을 선택해주세요");

            //     const _carts = selected.map(({ _id }) => _id);
            //     this.$store.dispatch("pickup", _carts);

            //     this.$router.push("/shop/order");
            // } catch (error) {
            //     this.$handleError(error);
            // }
        },

        async remove(item) {
            if (this.loading) return;
            else this.loading = true;

            try {
                await api.v1.me.carts.delete(item);
            } finally {
                this.loading = false;
                this.init();
            }
        },

        async removeSelected() {
            if (this.loading) return;
            else this.loading = true;
            try {
                for (const cart of this.selected) {
                    await api.v1.me.carts.delete(cart);
                }
            } finally {
                this.loading = false;
                this.init();
            }
        },

        async removeDisabled() {
            if (this.loading) return;
            else this.loading = true;

            try {
                for (const cart of carts__outOfStock) {
                    await api.v1.me.carts.delete(cart);
                }
            } finally {
                this.loading = false;
                this.init();
            }
        },

        async createBillKey() {
            let cardInfo = {
                ...this.cardInfo,
                simplePassword: CryptoAES.encrypt(this.cardInfo.simplePassword),
                confirmSimplePassword: CryptoAES.encrypt(this.cardInfo.confirmSimplePassword),
            };

            let { success } = await api.v1.me.cards.post(cardInfo);
            if (success) {
                this.$refs.cardSuccess.open("cart");
            }
        },
    },
};
</script>
