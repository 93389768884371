var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center",
      "justify": "center",
      "justify-xl": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "w-md-500px",
    attrs: {
      "no-gutters": "",
      "align": "center",
      "justify": "center",
      "justify-md": "space-between"
    }
  }, [_c('v-col', [_c('v-row', {
    staticClass: "row--xxs text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("선택상품금액")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('strong', {
    staticClass: "d-inline-flex align-center font-size-18 font-size-md-20"
  }, [_vm._v(" " + _vm._s(_vm.productPrice.format())), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-3"
    }
  }, [_vm._v("mdi-plus")])], 1), _c('v-col', [_c('v-row', {
    staticClass: "row--xxs text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("배송비")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('strong', {
    staticClass: "d-inline-flex align-center font-size-18 font-size-md-20"
  }, [_vm._v(" " + _vm._s(_vm.deliveryPrice.format())), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-3"
    }
  }, [_vm._v("mdi-minus")])], 1), _c('v-col', [_c('v-row', {
    staticClass: "row--xxs text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("할인예상금액")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('strong', {
    staticClass: "d-inline-flex align-center font-size-18 font-size-md-20 red--text"
  }, [_vm._v(" " + _vm._s(_vm.discountPrice.format())), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])])])], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "align-self-stretch",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "d-none d-xl-flex h-100",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "pr-10px pr-xl-0"
  }, [_c('v-row', {
    staticClass: "row--sm text-center",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs mb-n4px mb-xl-0"
  }, [_vm._v("주문금액")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('strong', {
    staticClass: "d-inline-flex align-center font-size-18 font-size-md-20 primary--text mt-n10px"
  }, [_vm._v(" " + _vm._s(_vm.totalPrice.format())), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])])])], 1)], 1)]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large w-100 min-w-sm-200px",
    attrs: {
      "x-large": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('order', _vm.selected);
      }
    }
  }, [_vm._v(_vm._s(_vm.regular ? "정기배송 신청하기" : "선택 주문하기"))])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }