var render = function render(){
  var _vm$form, _vm$form2, _vm$form2$createdAt, _vm$form2$createdAt$t;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "560",
      "maxWidth": "100%"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', _vm._b({
    staticClass: "d-flex flex-column"
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.typeText) + " 거절 사유 "), _c('v-spacer'), _c('v-icon', {
    on: {
      "click": _vm.close
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-card-subtitle', [_vm._v(" 상품주문번호: " + _vm._s((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : _vm$form.purchaseNo) + " | 상품주문일자 : " + _vm._s((_vm$form2 = _vm.form) === null || _vm$form2 === void 0 ? void 0 : (_vm$form2$createdAt = _vm$form2.createdAt) === null || _vm$form2$createdAt === void 0 ? void 0 : (_vm$form2$createdAt$t = _vm$form2$createdAt.toDate) === null || _vm$form2$createdAt$t === void 0 ? void 0 : _vm$form2$createdAt$t.call(_vm$form2$createdAt)) + " ")]), _c('v-divider'), _c('v-card-text', [_c('purchase-item', {
    attrs: {
      "value": _vm.form,
      "mode": "view",
      "withQuantity": ""
    }
  }), _c('v-divider'), _c('v-textarea', _vm._b({
    staticClass: "mt-5",
    attrs: {
      "value": _vm.form.feedbackMessage || `${_vm.typeText} 신청이 거절되었습니다`,
      "readonly": ""
    }
  }, 'v-textarea', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }