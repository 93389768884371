var render = function render(){
  var _vm$product;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('v-row', {
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-8px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', [_c('v-img', _vm._b({
    staticClass: "grey lighten-5",
    attrs: {
      "width": "48",
      "height": "48"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "color": "transparent"
          }
        }, [_c('v-icon', {
          attrs: {
            "x-small": "",
            "color": "grey lighten-4"
          }
        }, [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  }, 'v-img', {
    src: _vm.src
  }, false))], 1)], 1), _c('v-col', {
    staticClass: "pa-8px"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_c('span', [_vm._v(" " + _vm._s(((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.name) || "-") + " ")])]), _c('div', {
    staticClass: "txt txt--xs txt--dark"
  }, [_vm._v(" " + _vm._s(_vm.option) + " ")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }