<template>
    <v-row no-gutters>
        <v-col cols="12" xl="">
            <product-item v-bind="{ product, isSoldOut }" hideDivider isLink>
                <template #select>
                    <v-simple-checkbox :value="isSelected" class="d-inline-block" @click.prevent.stop.capture="toggle" />
                </template>
            </product-item>
        </v-col>
        <v-col cols="auto" class="d-none d-xl-block"> <v-divider vertical /> </v-col>
        <v-col cols="12" xl="4">
            <div class="px-xl-20px py-xl-16px">
                <v-row class="row--sm">
                    <template v-for="(item, index) in carts">
                        <v-col :key="`item-${index}`" cols="12" :class="{ 'mt-n4px': index != 0 }">
                            <v-card tile :color="$vuetify.breakpoint.lgAndDown ? 'grey lighten-5' : 'transparent'">
                                <div class="pa-10px pa-xl-0">
                                    <v-row class="row--xs">
                                        <v-col>
                                            <span class="txt txt--xs">{{ formatCart(item) }}</span>
                                        </v-col>
                                        <v-col v-if="item?._supply || carts?.length - carts__supply?.length - 1" cols="auto">
                                            <v-icon dense color="grey lighten-3" @click="$emit('remove', item)">mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col v-if="index != carts.length - 1" :key="`item-${index}-divider`" cols="12" class="d-none d-xl-block"> <v-divider /> </v-col>
                    </template>
                    <v-col>
                        <cart-form :value="carts" v-bind="{ _product: product?._id }" @search="$emit('search')">
                            <template #activator="{ attrs, on }">
                                <div class="text-right text-xl-left">
                                    <v-btn v-bind="attrs" v-on="on" outlined color="grey" class="v-size--xx-small w-100 w-md-auto">주문수정</v-btn>
                                </div>
                            </template>
                        </cart-form>
                    </v-col>
                </v-row>
            </div>
        </v-col>
        <v-col cols="auto" class="d-none d-xl-block"> <v-divider vertical /> </v-col>
        <v-col cols="12" xl="2">
            <!-- S: PC -->
            <div class="d-none d-xl-flex align-center h-100 pa-12px px-xl-20px py-md-16px">
                <v-row class="row--xxs text-center">
                    <v-col cols="12">
                        <div class="txt txt--xs">상품금액</div>
                    </v-col>
                    <v-col cols="12">
                        <strong class="d-inline-flex align-center font-size-18 font-size-md-20">{{ totalPrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small></strong>
                    </v-col>
                    <v-col cols="12">
                        <div class="txt txt--xs txt--light">배송비 {{ deliveryPrice.format() }}원</div>
                    </v-col>
                </v-row>
            </div>
            <!-- E: PC -->

            <!-- S: Mobile -->
            <div class="d-xl-none py-16px">
                <div>
                    <v-row no-gutters justify="space-between" class="txt txt--xs txt--dark font-weight-medium">
                        <v-col cols="auto"> 상품금액 </v-col>
                        <v-col cols="auto">
                            <strong>{{ totalPrice.format() }}원</strong>
                        </v-col>
                    </v-row>
                </div>
                <div class="mt-8px">
                    <v-row no-gutters justify="space-between" class="txt txt--xs">
                        <v-col cols="auto"> 배송비 </v-col>
                        <v-col cols="auto">
                            <span class="font-weight-medium">{{ deliveryPrice.format() }}원</span>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <!-- E: Mobile -->
        </v-col>
        <v-col cols="auto" class="d-none d-xl-block"> <v-divider vertical /> </v-col>
        <v-col cols="12"> <v-divider /> </v-col>
    </v-row>
</template>

<script>
import { mapState } from "vuex";

import LikeBtn from "../../like/like-btn.vue";
import CartForm from "./cart-form.vue";
import ProductItem from "../product/product-item.vue";

export default {
    components: {
        LikeBtn,
        CartForm,
        ProductItem,
    },
    props: {
        value: { type: Array, default: () => [] }, // selected
        carts: { type: Array, default: () => [] },
        product: { type: Object, default: () => ({}) },
    },
    computed: {
        ...mapState(["shippings"]),
        isSoldOut() {
            return this.product?.soldout;
        },
        isSelected() {
            const _selected = this.value.map(({ _id }) => _id);
            return !this.carts.some(({ _id }) => !_selected.includes(_id));
        },
        totalPrice() {
            return this.carts.reduce((total, item) => total + item.salePrice * item.amount, 0);
        },
        deliveryPrice() {
            return this.$getDeliveryPrice(this.carts, this.shippings);
        },
        carts__supply() {
            return this.carts.filter(({ _supply }) => _supply);
        },
    },
    methods: {
        toggle() {
            console.log("toggle");
            const _selected = this.value.map(({ _id }) => _id);
            const pushbales = this.carts.filter(({ _id }) => !_selected.includes(_id));
            if (pushbales.length) this.$emit("input", [...this.value, ...pushbales]);
            else {
                const _carts = this.carts.map(({ _id }) => _id);
                const selected = this.value.filter(({ _id }) => !_carts.includes(_id));
                this.$emit("input", selected);
            }
        },
        formatCart({ name, amount, price }) {
            let text = "";

            if (name) text += `${name} `;
            if (amount) text += `/ ${amount.format()}개 `;
            if (name != "본품") {
                if (0 < price) text += `(+${(price * amount).format()}원)`;
                if (price < 0) text += `(${(price * amount).format()}원)`;
            }
            return text.trim();
        },
    },
};
</script>

<style></style>
