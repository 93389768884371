<template>
    <client-page>
        <mypage-layout titleSpacingLg title="배송지 관리">
            <template v-if="items.length">
                <div class="border-t border-2 border-dark">
                    <v-card v-bind="{ loading }" v-for="item in items" :key="item?._id">
                        <div class="py-16px">
                            <v-row align-md="center" class="ma-n8px">
                                <v-col class="pa-8px">
                                    <v-card color="transparent">
                                        <div class="txt txt--sm txt--dark font-weight-medium">
                                            <v-row align="center" class="row--xs">
                                                <v-col cols="auto"> {{ item.title }} ({{ item.name }}) </v-col>
                                                <v-col v-if="item.isDefault" cols="auto">
                                                    <v-chip x-small color="primary">기본배송지</v-chip>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <div class="txt txt--xs txt--light mt-4px mt-md-8px">
                                            <div>{{ item?.phone }}</div>
                                            <div>{{ item.address }}</div>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="auto" class="pa-8px">
                                    <div class="w-md-150px">
                                        <v-row class="row--xs">
                                            <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                                <destination-form :value="item" @input="search">
                                                    <template #activator="{attrs, on}">
                                                        <v-btn v-bind="attrs" v-on="on" block outlined color="primary">수정</v-btn>
                                                    </template>
                                                </destination-form>
                                            </v-col>
                                            <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                                <v-btn v-on:click.prevent block outlined color="grey" @click="remove(item)">삭제</v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                        <v-divider />
                    </v-card>
                </div>

                <div class="btn-wrap btn-wrap--lg">
                    <destination-form @input="search">
                        <template #activator="{attrs, on}">
                            <v-row justify="center" class="row--sm">
                                <v-col cols="6" sm="auto">
                                    <v-btn v-bind="attrs" v-on="on" color="primary" class="v-size--xx-large w-100 min-w-sm-200px">배송지 등록하기</v-btn>
                                </v-col>
                            </v-row>
                        </template>
                    </destination-form>
                </div>
            </template>
            <template v-else>
                <div class="text-center">
                    <div class="mb-18px mb-md-24px">
                        <v-icon size="60px" color="primary">mdi-truck</v-icon>
                    </div>
                    <div class="tit tit--sm">등록된 배송지가 없습니다.</div>
                    <div class="txt txt--sm mt-8px">배송지를 등록해주세요.</div>

                    <div class="btn-wrap btn-wrap--lg">
                        <destination-form @input="search">
                            <template #activator="{attrs, on}">
                                <v-row justify="center" class="row--sm">
                                    <v-col cols="6" sm="auto">
                                        <v-btn v-bind="attrs" v-on="on" color="primary" class="v-size--xx-large w-100 min-w-sm-200px">배송지 등록하기</v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                        </destination-form>
                    </div>
                </div>

                <v-layout column class="py-15">
                    <v-card-actions> </v-card-actions>
                </v-layout>
            </template>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import DestinationForm from "@/components/client/shop/destinations/destination-form.vue";

export default {
    components: {
        ClientPage,
        MypageLayout,
        DestinationForm,
    },
    data: () => ({
        destinations: [],

        loading: false,
    }),
    computed: {
        items() {
            return [...this.destinations];
        },
    },
    mounted() {
        this.init();
        this.search();
    },
    methods: {
        init() {
            this.destinations = [];
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.destinations = (await api.v1.me.destinations.gets())?.destinations;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        async remove(item) {
            if (!confirm(`"${item?.title}" 배송지를 삭제하시겠습니까?`)) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                await api.v1.me.destinations.delete(item);
                alert("삭제되었습니다");
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
                this.search();
            }
        },
    },
};
</script>
