<template>
    <client-page>
        <mypage-layout title="적립금 내역">
            <page-section class="page-section--sm py-0">
                <template #containerImmersive>
                    <point-card />
                </template>
            </page-section>

            <page-section class="page-section--sm">
                <template #containerImmersive>
                    <v-data-table v-bind="{ headers, items }" hide-default-footer disable-filtering disable-pagination disable-sort class="v-data-table--default tbody-tr-cursor-pointer" no-data-text="적립금 내역이 없습니다">
                        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                        <template #[`item.amount`]="{ value }">
                            <span :class="{ 'red--text': (value || 0) < 0 }">{{ (value || 0) > 0 ? "+" : "" }}{{ value?.format?.() || 0 }}원</span>
                        </template>
                    </v-data-table>

                    <div class="pagination-wrap">
                        <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
                    </div>
                </template>
            </page-section>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";

import PointCard from "@/components/client/mypage/points/point-card.vue";

const headers = [
    { width: "25%", align: "center", text: "일자", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
    { width: "25%", align: "center", text: "비고", value: "remark" },
    { width: "25%", align: "center", text: "금액", value: "amount" },
    { width: "25%", align: "center", text: "남은 금액", value: "remain", formatter: (value) => `${value?.format?.() || value || 0}원` },
].map((item) => ({ ...item, formatter: item?.formatter ?? ((value) => value ?? "-") }));

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,

        PointCard,
    },

    data: () => ({
        points: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,

        headers,
    }),
    computed: {
        items() {
            return [...this.points].map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                this.$handleError(error);
            }
        },

        async search() {
            this.loading = true;
            try {
                let { skip, limit, params } = this;
                var { summary, points } = await api.v1.me.points.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.points = points;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
