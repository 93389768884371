<template>
    <u-dialog v-model="shows" title="카드정보 입력" persistent width="400">
        <page-section class="page-section--xs py-0">
            <template #containerImmersive>
                <tit-wrap-small title="카드명" />
                <v-text-field v-model="value.name" v-bind="{ ...attrs_input }" clear-icon placeholder="카드명을 입력해주세요." />
            </template>
        </page-section>

        <page-section class="page-section--xs pb-0">
            <template #containerImmersive>
                <tit-wrap-small title="생년월일(6)/사업자번호" />
                <v-text-field v-model="value.idNo" v-bind="{ ...attrs_input }" clear-icon placeholder="생년월일 또는 사업자번호를 입력해주세요." />
            </template>
        </page-section>

        <page-section class="page-section--xs pb-0">
            <template #containerImmersive>
                <tit-wrap-small title="카드번호" />
                <v-row align="center" class="row--xs">
                    <v-col>
                        <v-text-field v-model="cardNo1" v-bind="{ ...attrs_input }" placeholder="****" :maxlength="4" />
                    </v-col>
                    <v-col>
                        <v-text-field v-model="cardNo2" v-bind="{ ...attrs_input }" placeholder="****" type="password" :maxlength="4" />
                    </v-col>
                    <v-col>
                        <v-text-field v-model="cardNo3" v-bind="{ ...attrs_input }" placeholder="****" type="password" :maxlength="4" />
                    </v-col>
                    <v-col>
                        <v-text-field v-model="cardNo4" v-bind="{ ...attrs_input }" placeholder="****" :maxlength="4" />
                    </v-col>
                </v-row>
            </template>
        </page-section>

        <page-section class="page-section--xs pb-0">
            <template #containerImmersive>
                <v-row>
                    <v-col cols="6">
                        <tit-wrap-small title="유효기간" />
                        <v-row align="center" class="row--xs">
                            <v-col>
                                <v-text-field v-model="value.expMonth" v-bind="{ ...attrs_input }" placeholder="MM" :maxlength="2" />
                            </v-col>
                            <v-col>
                                <v-text-field v-model="value.expYear" v-bind="{ ...attrs_input }" placeholder="YY" :maxlength="2" />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6">
                        <tit-wrap-small title="비밀번호 2자리" />
                        <v-row align="center" class="row--xs">
                            <v-col cols="auto">
                                <v-text-field v-model="cardPw1" v-bind="{ ...attrs_input }" placeholder="*" type="password" :maxlength="1" class="mw-40px" />
                            </v-col>
                            <v-col cols="auto">
                                <v-text-field v-model="cardPw2" v-bind="{ ...attrs_input }" placeholder="*" type="password" :maxlength="1" class="mw-40px" />
                            </v-col>
                            <v-col cols="auto">
                                <span class="font-size-14">●</span>
                            </v-col>
                            <v-col cols="auto">
                                <span class="font-size-14">●</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </template>
        </page-section>

        <div class="btn-wrap">
            <v-row class="row--sm">
                <v-col>
                    <v-btn block outlined color="grey" class="v-size--xx-large" @click="shows = false">취소</v-btn>
                </v-col>
                <v-col>
                    <v-btn block color="primary" class="v-size--xx-large" @click="next">간편비밀번호등록</v-btn>
                </v-col>
            </v-row>
        </div>
    </u-dialog>
</template>

<script>
import { attrs_input } from "@/assets/variables";

import UDialog from "@/components/client/dumb/u-dialog.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapSmall from "@/components/client/dumb/tit-wrap-small.vue";
import ShopProductCarts from "../../shop/shop-product-carts.vue";
import ShopProductOptions from "../../shop/shop-product-options.vue";
import ShopProductSupplies from "../../shop/shop-product-supplies.vue";

export default {
    components: {
        UDialog,
        PageSection,
        TitWrapSmall,
        ShopProductCarts,
        ShopProductOptions,
        ShopProductSupplies,
    },
    props: {
        value: { type: Object, default: {} },
    },
    data() {
        return {
            attrs_input,

            shows: false,

            cardNo1: null,
            cardNo2: null,
            cardNo3: null,
            cardNo4: null,

            cardPw1: null,
            cardPw2: null,
        };
    },
    methods: {
        open() {
            this.shows = true;
        },
        next() {
            if (this.validate()) {
                this.shows = false;
                this.$emit("step3");
            }
        },
        validate() {
            try {
                if (!this.value.name) throw new Error("카드명을 입력해주세요");
                if (!this.value.idNo) throw new Error("주민번호 또는 사업자번호를 입력해주세요");
                if (!this.cardNo1 || this.cardNo1.length !== 4 || !this.cardNo2 || this.cardNo2.length !== 4 || !this.cardNo3 || this.cardNo3.length !== 4 || !this.cardNo4 || this.cardNo4.length !== 4) throw new Error("카드번호를 입력해주세요");
                if (!this.value.cardNo) throw new Error("카드번호를 입력해주세요");
                if (!this.value.expMonth || !this.value.expYear || this.value.expMonth.length !== 2 || this.value.expYear.length !== 2) throw new Error("유효기간을 입력해주세요");
                if (!this.cardPw1 || this.cardPw1.length !== 1 || !this.cardPw2 || this.cardPw2.length !== 1) throw new Error("비밀번호를 입력해주세요");
                if (!this.value.cardPw) throw new Error("비밀번호를 입력해주세요");
                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
    },
    watch: {
        cardPw1() {
            this.value.cardPw = this.cardPw1 && this.cardPw2 ? `${this.cardPw1}${this.cardPw2}` : null;
        },
        cardPw2() {
            this.value.cardPw = this.cardPw1 && this.cardPw2 ? `${this.cardPw1}${this.cardPw2}` : null;
        },
        cardNo1() {
            this.value.cardNo = this.cardNo1 && this.cardNo2 && this.cardNo3 && this.cardNo4 ? `${this.cardNo1}${this.cardNo2}${this.cardNo3}${this.cardNo4}` : null;
        },
        cardNo2() {
            this.value.cardNo = this.cardNo1 && this.cardNo2 && this.cardNo3 && this.cardNo4 ? `${this.cardNo1}${this.cardNo2}${this.cardNo3}${this.cardNo4}` : null;
        },
        cardNo3() {
            this.value.cardNo = this.cardNo1 && this.cardNo2 && this.cardNo3 && this.cardNo4 ? `${this.cardNo1}${this.cardNo2}${this.cardNo3}${this.cardNo4}` : null;
        },
        cardNo4() {
            this.value.cardNo = this.cardNo1 && this.cardNo2 && this.cardNo3 && this.cardNo4 ? `${this.cardNo1}${this.cardNo2}${this.cardNo3}${this.cardNo4}` : null;
        },
    },
};
</script>
