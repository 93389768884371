var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('mypage-layout', {
    attrs: {
      "titleSpacingLg": "",
      "title": "회차정보"
    }
  }, _vm._l(_vm.items, function (order, index) {
    return _c('page-section', {
      key: order._id,
      class: index != 0 ? 'page-section--sm pb-0' : 'page-section--sm py-0'
    }, [_c('template', {
      slot: "containerImmersive"
    }, [_c('tit-wrap-line', {
      attrs: {
        "noDivider": ""
      }
    }, [_c('template', {
      slot: "titleContents"
    }, [_c('div', {
      staticClass: "d-flex flex-column flex-sm-row align-sm-center"
    }, [_c('div', {
      staticClass: "pr-sm-8px mb-4px mb-sm-0"
    }, [_c('v-chip', {
      attrs: {
        "outlined": "",
        "color": "primary"
      }
    }, [_vm._v(_vm._s(_vm.items.length - index) + "회차")])], 1), _c('span', {
      staticClass: "tit tit--xxs"
    }, [_vm._v(_vm._s(`${order.purchases[0].product.name} 외 ${order.purchases.length - 1}개 정기배송`) + " ")]), _c('span', {
      staticClass: "txt txt--sm pl-sm-8px mt-4px mt-sm-0"
    }, [_vm._v(_vm._s(_vm.$dayjs(order.createdAt).format("YYYY.MM.DD")))])])])], 2), _c('div', {
      staticClass: "border-t border-2 border-dark"
    }, [_vm._l(order.purchases, function (purchase) {
      return [_c('purchase-item', {
        key: purchase._id,
        attrs: {
          "value": purchase,
          "isOrderListItem": ""
        },
        on: {
          "click": function ($event) {
            _vm.$refs[purchase._order][0].$data.shows = true;
          }
        }
      })];
    })], 2)], 1)], 2);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }