<template>
    <u-dialog v-model="shows" :title="title + ' 신청'" persistent width="600">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }">
            <div>
                <div class="txt txt--xs">상품주문일자 : {{ form?.createdAt?.toDate?.() }}</div>
                <div class="pb-8px">
                    <purchase-item :value="form" mode="view" withQuantity />
                </div>
                <v-divider />

                <!-- S:유형 -->
                <template v-if="type != PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value">
                    <page-section class="page-section--xs pb-0">
                        <template #containerImmersive>
                            <tit-wrap-small :title="title + '유형'"></tit-wrap-small>
                            <v-radio-group v-model="claimStatus" row hide-details>
                                <v-radio label="교환" :value="PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value" />
                                <v-radio label="반품 후 환불" :value="PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value" />
                            </v-radio-group>
                        </template>
                    </page-section>
                </template>
                <!-- E:유형 -->

                <!-- S:사유 -->
                <page-section class="page-section--xs pb-0">
                    <template #containerImmersive>
                        <tit-wrap-small :title="typeText + '사유'"></tit-wrap-small>
                        <v-select v-model="form.claimReason" :placeholder="`${title} 사유 선택해주세요`" :items="claimReasonItems" v-bind="{ ...attrs_input, loading }" />
                    </template>
                </page-section>
                <!-- E:사유 -->

                <!-- S:상세 -->
                <page-section class="page-section--xs pb-0">
                    <template #containerImmersive>
                        <tit-wrap-small :title="typeText + '상세'"></tit-wrap-small>
                        <v-textarea v-model="form.claimReasonDetails" :placeholder="`${title} 상세를 입력해주세요`" v-bind="{ ...attrs_input, loading }" />
                    </template>
                </page-section>
                <!-- E:상세 -->
            </div>

            <div class="btn-wrap">
                <v-row class="row--sm">
                    <v-col>
                        <v-btn block outlined color="grey" class="v-size--xx-large" @click="close">취소</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn block color="primary" class="v-size--xx-large" @click="save"> {{ title }} 신청 </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-card>
    </u-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, initPurchase, PURCHASE_CLAIM_STATUSES } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import TitWrapSmall from "@/components/client/dumb/tit-wrap-small.vue";
import PurchaseItem from "./purchase-item.vue";

export default {
    components: {
        PageSection,
        UDialog,
        TitWrapSmall,
        PurchaseItem,
    },
    props: {
        value: { type: Object, default: initPurchase }, // purchases
        type: { type: String, default: PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value },
    },
    data: () => ({
        form: initPurchase(),

        // ui
        shows: false,
        loading: false,
        claimStatus: null,

        attrs_input,
        PURCHASE_CLAIM_STATUSES,
    }),
    computed: {
        typeText() {
            return {
                [PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value]: "취소",
                [PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value]: "반품",
                [PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value]: "교환",
            }[this.claimStatus];
        },
        title() {
            return {
                [PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value]: "취소",
                [PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value]: "교환·반품",
                [PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value]: "교환·반품",
            }[this.type];
        },
        claimReasonItems() {
            let items = [];

            items.push("단순변심");
            if (this.type != PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value) items.push("상품불량");
            items.push("기타");

            return items;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        shows(shows) {
            if (shows) this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initPurchase({ ...this.value });
            this.claimStatus = this.type;
        },

        emit() {
            this.$emit("input", this.form);
        },

        close() {
            this.shows = false;
        },

        async save() {
            if (this.loading) return;
            this.loading = true;

            try {
                let { _id, claimReason, claimReasonDetails } = this.form;
                let { claimStatus } = this;
                let form = {
                    _id,
                    claimStatus,
                    claimReason,
                    claimReasonDetails,
                };
                this.form = (await api.v1.me.purchases.put(form))?.purchase;
                this.emit();
                alert(`해당 상품주문에 대한 "${this.typeText}" 요청이 진행되었습니다.`);
                this.close();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
