<template>
    <client-page>
        <mypage-layout titleSpacingLg title="정기배송">
            <template>
                <regular-order-head v-model="selected" v-bind="{ regularOrders }" v-on="{ removeSelected, removeDisabled }" />

                <!-- S: cart-body -->
                <div class="border-t border-2 border-dark">
                    <v-row no-gutters v-for="regularOrder in regularOrders" :key="regularOrder._id">
                        <v-col cols="12" xl="">
                            <regular-order-item v-model="selected" v-bind="{ regularOrder, product: regularOrder.products[0] }" v-on="{ update, updateSelected }" />
                        </v-col>
                    </v-row>
                </div>
            </template>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import { mapMutations, mapState } from "vuex";

import RegularOrderHead from "@/components/client/mypage/regular-order/regular-order-head.vue";
import RegularOrderItem from "@/components/client/mypage/regular-order/regular-order-item.vue";
import CardForm from "@/components/client/mypage/card/card-form.vue";
import CardInputForm from "@/components/client/mypage/card/card-input-form.vue";
import CardSuccess from "@/components/client/mypage/card/card-success.vue";
import SimplePaymentPassword from "@/components/client/mypage/card/simple-payment-password.vue";
import ConfirmSimplePaymentPassword from "@/components/client/mypage/card/confirm-simple-payment-password.vue";
import RegularOrderFoot from "@/components/client/mypage/regular-order/regular-order-foot.vue";
import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";

export default {
    components: {
        RegularOrderHead,
        RegularOrderItem,
        CardForm,
        CardInputForm,
        CardSuccess,
        SimplePaymentPassword,
        ConfirmSimplePaymentPassword,
        RegularOrderFoot,
        ClientPage,
        MypageLayout,
    },
    props: {
        type: { type: String, default: "cart" },
    },
    data: () => ({
        carts: [],
        regularOrders: [],
        selected: [],
        deliveryType: "regular",
        deliveryCycle: "1week",
        deliveryCycleItems: [
            { text: "1주일", value: "1week" },
            { text: "2주일", value: "2week" },
            { text: "1달", value: "1month" },
            { text: "3달", value: "3month" },
        ],
        cardInfo: {
            name: null,
            cardNo: null,
            expYear: null,
            expMonth: null,
            idNo: null,
            cardPw: null,
            simplePassword: null,
            confirmSimplePassword: null,
        },
        loading: false,
    }),
    computed: {
        ...mapState(["accessToken", "shippings"]),
        productPirce() {
            return this.$getProductPrice(this.carts);
        },
        discountPirce() {
            return this.$getDiscountPrice(this.carts);
        },
        deliveryPrice() {
            return this.$getDeliveryPrice(this.carts, this.shippings);
        },
        totalPrice() {
            return this.productPirce - this.discountPirce + this.deliveryPrice;
        },
        // carts__outOfStock() {
        //     return this.selected.filter(({ stock = 0 }) => stock <= 0);
        // },
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapMutations(["setCarts"]),
        async init() {
            try {
                this.selected = [];
                await this.search();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.selected = [...this.regularOrders];
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { regularOrders } = await api.v1.shop.regularOrders.gets();
                this.regularOrders = regularOrders;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
                // this.selected = [...this.cartMaps.flatMap(({ groups }) => groups.flatMap(({ carts }) => carts))];
            }
        },
        async update(data) {
            await api.v1.shop.regularOrders.put(data);
            await this.init();
        },

        async removeSelected() {
            if (this.loading) return;
            else this.loading = true;
            try {
                for (const regularOrder of this.selected) {
                    await api.v1.shop.regularOrders.delete(regularOrder);
                }
            } finally {
                this.loading = false;
                this.init();
            }
        },

        async removeDisabled() {
            if (this.loading) return;
            else this.loading = true;

            try {
                for (const cart of carts__outOfStock) {
                    await api.v1.me.carts.delete(cart);
                }
            } finally {
                this.loading = false;
                this.init();
            }
        },

        updateSelected(selected) {
            this.selected = selected;
        },
    },
};
</script>
