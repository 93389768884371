<template>
    <u-dialog v-model="shows" title="주문수정" persistent width="600">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <div class="mt-n16px">
            <product-item hideDivider v-bind="{ product }" />
        </div>

        <v-divider class="mt-md-8px" />
        <page-section class="page-section--xs">
            <template #containerImmersive>
                <div>
                    <v-row no-gutters>
                        <v-col cols="12" md="auto">
                            <div class="txt--light w-md-80px">배송방법</div>
                        </v-col>
                        <v-col cols="12" md="">
                            <div>택배</div>
                        </v-col>
                    </v-row>
                </div>
                <div class="pt-4px pt-md-8px">
                    <v-row no-gutters>
                        <v-col cols="12" md="auto">
                            <div class="txt--light w-md-80px">배송비정책</div>
                        </v-col>
                        <v-col cols="12" md="">
                            <div>{{ product?.deliveryMessage }}</div>
                        </v-col>
                    </v-row>
                </div>
            </template>
        </page-section>

        <v-divider />
        <!-- 상품 옵션 -->
        <div v-if="product?.optionsEnabled || product?.suppliesEnabled || product?.regularDeliveryEnabled" class="txt txt--xs">
            <!-- 상품 선택옵션 -->
            <page-section v-if="product?.optionsEnabled" class="page-section--xs pb-0">
                <template #containerImmersive>
                    <tit-wrap-small title="선택옵션"></tit-wrap-small>
                    <shop-product-options v-model="carts" v-bind="{ product }" />
                </template>
            </page-section>
            <!-- 상품 추가옵션 -->
            <page-section v-if="product?.suppliesEnabled" class="page-section--xs pb-0">
                <template #containerImmersive>
                    <tit-wrap-small title="추가옵션"></tit-wrap-small>
                    <shop-product-supplies v-model="carts" v-bind="{ product }" />
                </template>
            </page-section>
        </div>

        <!-- 선택한 옵션 -->
        <shop-product-carts v-model="carts" />

        <template #actions>
            <div class="w-100">
                <div>
                    <v-row no-gutters align="center" justify="space-between" class="txt txt--xs">
                        <v-col cols="auto"> 상품금액 </v-col>
                        <v-col cols="auto">
                            <strong class="d-inline-flex align-center font-size-18 font-size-md-20 line-height-1 txt--dark"> {{ productPrice?.format?.() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                        </v-col>
                    </v-row>
                </div>
                <div class="mt-8px">
                    <v-row no-gutters align="center" justify="space-between" class="txt txt--xs">
                        <v-col cols="auto">할인금액</v-col>
                        <v-col cols="auto">-{{ discountPrice?.format?.() }} 원</v-col>
                    </v-row>
                </div>
                <div class="mt-8px">
                    <v-row no-gutters align="center" justify="space-between" class="txt txt--xs">
                        <v-col cols="auto">배송비</v-col>
                        <v-col cols="auto">{{ deliveryPrice?.format?.() }} 원</v-col>
                    </v-row>
                </div>
                <div class="mt-8px">
                    <v-row no-gutters align="center" justify="space-between" class="txt txt--xs">
                        <v-col cols="auto">총 금액</v-col>
                        <v-col cols="auto">
                            <strong class="d-inline-flex align-center font-size-18 font-size-md-20 line-height-1 primary--text"> {{ totalPrice?.format?.() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                        </v-col>
                    </v-row>
                </div>

                <div class="btn-wrap">
                    <v-row class="row--sm">
                        <v-col>
                            <v-btn block outlined color="grey" class="v-size--xx-large" @click="shows = false">취소</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn block color="primary" class="v-size--xx-large" @click="save">수정</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </template>
    </u-dialog>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";

import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import ProductItem from "../product/product-item.vue";
import TitWrapSmall from "@/components/client/dumb/tit-wrap-small.vue";
import VerticalTable from "@/components/dumb/vertical-table.vue";
import ShopProductCarts from "../../shop/shop-product-carts.vue";
import ShopProductOptions from "../../shop/shop-product-options.vue";
import ShopProductSupplies from "../../shop/shop-product-supplies.vue";
import ShopProductRegularDelivery from '../../shop/shop-product-regular-delivery.vue';

export default {
    props: {
        value: { type: Array, default: () => [] }, // carts
        _product: { type: String },
    },
    components: {
        PageSection,
        UDialog,
        ProductItem,
        TitWrapSmall,
        VerticalTable,
        ShopProductCarts,
        ShopProductOptions,
        ShopProductSupplies,
        ShopProductRegularDelivery,
    },
    data: () => ({
        carts: [],
        product: {},
        shows: false,
    }),
    computed: {
        ...mapState(["shippings"]),
        shippingInfo() {
            return [
                { term: "배송방법", data: "택배" },
                { term: "배송비", data: this.product?.deliveryMessage || "-" },
            ].map((item) => ({ ...item, dense: true, noDevider: true }));
        },
        productPrice() {
            return this.$getProductPrice(this.carts);
        },
        deliveryPrice() {
            return this.$getDeliveryPrice(this.carts, this.shippings);
        },
        discountPrice() {
            return this.$getDiscountPrice(this.carts);
        },
        totalPrice() {
            return this.productPrice + this.deliveryPrice - this.discountPrice;
        },
    },
    mounted() {
        this.sync();
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        shows() {
            this.sync();
        },
        _product() {
            this.init();
        },
    },
    methods: {
        sync() {
            this.carts = JSON.parse(JSON.stringify(this.value));
        },
        async init() {
            this.product = (await api.v1.shop.products.get({ _id: this._product }))?.product;
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;
            try {
                const _carts__new = this.carts.map(({ _id }) => _id);
                const _carts__old = this.value.map(({ _id }) => _id);

                const added = this.carts.filter(({ _id }) => !_carts__old.includes(_id));
                if (added.length) await api.v1.me.carts.post({ carts: added });

                const deleted = this.value.filter(({ _id }) => !_carts__new.includes(_id));
                await Promise.all(deleted.map(async (cart) => await api.v1.me.carts.delete(cart)));

                const modified = this.carts.filter(({ _id }) => _carts__old.includes(_id));
                await Promise.all(modified.map(async (cart) => await api.v1.me.carts.put(cart)));

                alert("수정되었습니다");
                this.shows = false;
                this.$emit("search");
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
