var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('mypage-layout', {
    attrs: {
      "title": "대시보드"
    }
  }, [_c('purchase-status', {
    attrs: {
      "hideOverall": "",
      "hideFilters": ""
    }
  }), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('section-like-list')], 1)], 2), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('section-reviewable-list')], 1)], 2), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "공지사항"
    }
  }), _c('router-view', {
    attrs: {
      "name": "board",
      "code": "consumer-notice-board",
      "skin": "board-notice"
    }
  })], 1)], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }