<template>
    <client-page>
        <mypage-layout title="회원탈퇴">
            <div class="mypage-contents__body">
                <v-tabs-items v-model="view">
                    <v-tab-item value="info">
                        <v-sheet>
                            <p>
                                회원탈퇴란, 리본바이옴 사이트에 대한 이용해지를 의미합니다. <br />
                                리본바이옴에서 발송하는 광고성 이메일의 경우 회원탈퇴 요청 접수 후 24시간 이내에 발송이 중지됩니다.<br />
                                다만, 드물게 데이터 처리사정상 일부 지연될 수 있으니 혹 회원탈퇴 요청 후  48시간이 지난 후에 광고성 이메일/SMS를 접수하시는 경우 당사에 연락하여 주시기 바랍니다.
                            </p>
                            <br />
                            <p>
                                <b>회원탈퇴 시 유의사항</b><br />
                                회원탈퇴 시 보유하고 계신 쿠폰 및 적립금은 즉시 소멸되며 동일한 아이디로 재가입 하더라도 복원되지 않습니다.
                            </p>
                            <br />
                            <p>
                                <b>탈퇴회원 회원정보 보전기간</b>
                            </p>
                            <ul>
                                <li>- 회원탈퇴가 완료되더라도 판/구매자의 권익을 보호하기 위해 다음과 같이 회원정보가 일정기간 보존됨을 알려드립니다.</li>
                                <li>
                                    - 관계 법령에 의거하여 보존이 필요한 경우에 한하여 보존됩니다.
                                    <v-card color="grey lighten-4" class="mt-3">
                                        <v-card-text>
                                            <ol>
                                                <li>1) 계약 및 청약철회 등에 관한기록 : 5년</li>
                                                <li>2) 대금결제 및 재화등의 공급에 관한 기록 : 5년</li>
                                                <li>3) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</li>
                                                <li>4) 부정이용 등에 관한 기록 : 1년</li>
                                            </ol>
                                        </v-card-text>
                                    </v-card>
                                </li>
                            </ul>

                            <v-row justify="center" class="mt-3">
                                <v-col cols="auto">
                                    <v-btn x-large color="error" @click="view = 'form'">
                                        <span> 탈퇴신청 </span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-tab-item>
                    <v-tab-item value="form">
                        <vertical-form-table v-model="form" v-bind="{ items }" />

                        <v-row justify="center" class="mt-3">
                            <v-col cols="auto">
                                <v-btn x-large @click="view = 'info'">
                                    <span> 취소 </span>
                                </v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn x-large color="error" @click="withdraw">
                                    <span> 탈퇴 </span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </mypage-layout>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

const initForm = (form = {}) => ({
    withdrawalReason: form?.withdrawalReason || null,
    withdrawalMessage: form?.withdrawalMessage || null,
});

export default {
    components: { ClientPage, MypageLayout, VerticalFormTable },
    data: () => ({
        view: "info",

        form: initForm(),
    }),
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        items() {
            return [
                {
                    key: "withdrawalReason",
                    term: "탈퇴 사유",
                    type: "text",
                },
                {
                    key: "withdrawalMessage",
                    term: "남기실 말씀",
                    type: "textarea",
                },
            ].map((item) => ({ ...item, dense: true, outlined: true }));
        },
    },
    watch: {
        view() {
            if (this.view == "form") this.form = initForm();
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            if (!this.accessToken) {
                alert("로그인 이후 이용가능합니다");
                this.$router.push("/login");
                return;
            }
        },
        async withdraw() {
            const go = confirm("정말로 탈퇴하시겠습니까?");
            if (!go) return;

            try {
                await this.postWithdraw(this.form);

                alert("탈퇴처리 되었습니다");

                this.$store.dispatch("logout").then(() => {
                    this.$router.push("/");
                });
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        postWithdraw({ withdrawalReason, withdrawalMessage }) {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.post("/api/v1/me/withraw", { withdrawalReason, withdrawalMessage });

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
};
</script>
