var render = function render(){
  var _vm$product, _vm$purchase, _vm$purchase$amount, _vm$purchase$amount$f, _vm$purchase2, _vm$purchase2$price, _vm$purchase2$price$f;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-n8px",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-8px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    attrs: {
      "rounded": "lg"
    }
  }, [_c('v-img', {
    attrs: {
      "min-width": "100",
      "aspect-ratio": 1 / 1,
      "src": `/res/shop/products/${_vm.product._id}/${_vm.product.thumb}`
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "pa-8px"
  }, [_c('router-link', {
    staticClass: "d-block mb-12px",
    attrs: {
      "to": `/shop/products/${(_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product._id}`
    }
  }, [_c('span', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v(_vm._s(_vm.product.name))])]), _c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_vm.purchase._option ? _c('v-chip', {
    attrs: {
      "x-small": "",
      "outlined": "",
      "color": "grey"
    }
  }, [_vm._v("옵션")]) : _vm._e(), _vm.purchase._supply ? _c('v-chip', {
    attrs: {
      "x-small": "",
      "outlined": "",
      "color": "grey"
    }
  }, [_vm._v("추가")]) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('span', {
    staticClass: "txt txt--xs"
  }, [_vm._v(" " + _vm._s(_vm.purchase.name) + " / " + _vm._s((_vm$purchase = _vm.purchase) === null || _vm$purchase === void 0 ? void 0 : (_vm$purchase$amount = _vm$purchase.amount) === null || _vm$purchase$amount === void 0 ? void 0 : (_vm$purchase$amount$f = _vm$purchase$amount.format) === null || _vm$purchase$amount$f === void 0 ? void 0 : _vm$purchase$amount$f.call(_vm$purchase$amount)) + "개 "), _vm.purchase._supply ? _c('span', [_vm._v(" (+" + _vm._s((_vm$purchase2 = _vm.purchase) === null || _vm$purchase2 === void 0 ? void 0 : (_vm$purchase2$price = _vm$purchase2.price) === null || _vm$purchase2$price === void 0 ? void 0 : (_vm$purchase2$price$f = _vm$purchase2$price.format) === null || _vm$purchase2$price$f === void 0 ? void 0 : _vm$purchase2$price$f.call(_vm$purchase2$price)) + "원)")]) : _vm._e()])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }