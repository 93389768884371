<template>
    <client-page>
        <mypage-layout title="주문내역">
            <purchase-status v-bind="{ loading }" />

            <page-section v-for="(order, index) in items" :key="order._id" class="page-section--sm pb-0">
                <template slot="containerImmersive">
                    <tit-wrap-line noDivider>
                        <template slot="titleContents">
                            <div class="d-flex flex-column flex-sm-row align-sm-center">
                                <span class="tit tit--xxs">{{ order?.createdAt?.toDate?.() }}</span>
                                <span class="txt txt--sm pl-sm-8px mt-4px mt-sm-0">주문번호: {{ order?.orderNo }}</span>
                            </div>
                        </template>
                        <template slot="default">
                            <order-item-complete v-bind="{ order }" v-on="{ init }" outlined color="grey" class="v-size--xx-small mr-2" />
                            <order-view v-model="orders[index]" :ref="orders[index]?._id">
                                <template #activator="{ attrs, on }">
                                    <v-btn v-bind="attrs" v-on="on" outlined color="grey" class="v-size--xx-small">상세보기</v-btn>
                                </template>
                            </order-view>
                        </template>
                    </tit-wrap-line>

                    <div class="border-t border-2 border-dark">
                        <template v-for="purchase in order.purchases">
                            <purchase-item :key="purchase._id" :value="purchase" isOrderListItem @click="$refs[purchase._order][0].$data.shows = true">
                                <template #actions>
                                    <v-row class="row--xs">
                                        <purchase-item-claim :value="purchase" v-on="{ init }">
                                            <template #activator="{ attrs, on }">
                                                <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                                    <v-btn v-bind="attrs" v-on="on" @click.prevent block outlined color="grey">취소 신청</v-btn>
                                                </v-col>
                                            </template>
                                        </purchase-item-claim>
                                        <purchase-item-claim-withdraw :value="purchase" v-on="{ init }">
                                            <template #activator="{ attrs, on }">
                                                <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                                    <v-btn v-bind="attrs" v-on="on" @click.prevent block outlined color="grey">취소 철회</v-btn>
                                                </v-col>
                                            </template>
                                        </purchase-item-claim-withdraw>
                                        <purchase-item-delivery :value="purchase">
                                            <template #activator="{ attrs, on }">
                                                <v-btn v-bind="attrs" v-on="on" @click.stop block outlined color="primary">배송 조회</v-btn>
                                            </template>
                                        </purchase-item-delivery>
                                        <purchase-item-claim :value="purchase" :type="PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value" v-on="{ init }">
                                            <template #activator="{ attrs, on }">
                                                <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                                    <v-btn v-bind="attrs" v-on="on" @click.prevent block outlined color="grey">교환·반품 신청</v-btn>
                                                </v-col>
                                            </template>
                                        </purchase-item-claim>
                                        <purchase-item-claim-withdraw :value="purchase" :type="PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value" v-on="{ init }">
                                            <template #activator="{ attrs, on }">
                                                <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                                    <v-btn v-bind="attrs" v-on="on" @click.prevent block outlined color="grey">교환·반품 철회</v-btn>
                                                </v-col>
                                            </template>
                                        </purchase-item-claim-withdraw>
                                        <purchase-item-claim-withdraw :value="purchase" :type="PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value" v-on="{ init }">
                                            <template #activator="{ attrs, on }">
                                                <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                                    <v-btn v-bind="attrs" v-on="on" @click.prevent block outlined color="grey">교환·반품 철회</v-btn>
                                                </v-col>
                                            </template>
                                        </purchase-item-claim-withdraw>
                                        <purchase-item-claim-reject :value="purchase">
                                            <template #activator="{ attrs, on, title }">
                                                <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                                    <v-btn v-bind="attrs" v-on="on" @click.prevent block outlined color="grey">{{ title }} 거절 사유</v-btn>
                                                </v-col>
                                            </template>
                                        </purchase-item-claim-reject>

                                        <review-form v-bind="{ purchase }" v-on="{ init }" v-if="purchase.reviewable">
                                            <template #activator="{ attrs, on }">
                                                <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                                    <v-btn v-bind="attrs" v-on="on" @click.prevent block outlined color="primary">리뷰 작성</v-btn>
                                                </v-col>
                                            </template>
                                        </review-form>
                                    </v-row>
                                </template>
                            </purchase-item>
                        </template>
                    </div>
                </template>
            </page-section>
            <v-fade-transition leave-absolute>
                <div v-show="!loading && !items?.length" class="text-center pt-80px">
                    <div class="mb-18px mb-md-24px">
                        <v-icon size="60px" color="primary">mdi-view-list</v-icon>
                    </div>
                    <div class="tit tit--sm">주문하신 상품이 없습니다.</div>
                    <div class="txt txt--sm mt-8px">원하시는 상품을 주문해주세요.</div>

                    <div class="btn-wrap btn-wrap--lg">
                        <v-row justify="center" class="row--sm">
                            <v-col cols="6" sm="auto">
                                <v-btn color="primary" to="/shop" class="v-size--xx-large w-100 min-w-sm-200px">쇼핑 계속하기</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-fade-transition>

            <div v-if="hasMoreItems" v-intersect.quiet="loadInfinite" class="btn-wrap">
                <v-btn outlined x-large block color="primary" v-bind="{ loading }" @click="loadMore"> 더 보기 </v-btn>
            </div>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import { PURCHASE_QUERY_CODES, PURCHASE_CLAIM_STATUSES } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";

import OrderView from "@/components/client/mypage/order/order-view.vue";
import ReviewForm from "@/components/client/shop/reviews/review-form.vue";
import PurchaseItem from "@/components/client/mypage/purchase/purchase-item.vue";
import PurchaseStatus from "@/components/client/mypage/purchase/purchase-status.vue";
import OrderItemComplete from "@/components/client/mypage/order/order-item-complete.vue";
import PurchaseItemClaim from "@/components/client/mypage/purchase/purchase-item-claim.vue";
import PurchaseItemDelivery from "@/components/client/mypage/purchase/purchase-item-delivery.vue";
import PurchaseItemClaimReject from "@/components/client/mypage/purchase/purchase-item-claim-reject.vue";
import PurchaseItemClaimWithdraw from "@/components/client/mypage/purchase/purchase-item-claim-withdraw.vue";

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,
        TitWrapLine,

        OrderView,
        ReviewForm,
        PurchaseItem,
        PurchaseStatus,
        OrderItemComplete,
        PurchaseItemClaim,
        PurchaseItemDelivery,
        PurchaseItemClaimReject,
        PurchaseItemClaimWithdraw,
    },
    data: function () {
        return {
            orders: [],
            purchases: [],

            limit: 10,
            summary: { totalCount: 0 },

            loading: false,
            PURCHASE_CLAIM_STATUSES,
        };
    },
    computed: {
        items() {
            return this.orders.map((order) => ({ ...order, purchases: [...(this.purchases || [])].filter((purchase) => order?._id == purchase?._order) }));
        },

        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit || 0;
        },

        headers() {
            let { page, skip, limit } = this;
            if (this.purchases.length < skip) {
                skip = this.purchases.length;
                limit = limit * page - this.purchases.length;
            }
            return { skip, limit };
        },
        params() {
            let { code, ...query } = this.$route.query;
            return { ...query, ...(PURCHASE_QUERY_CODES[code]?.params || {}) };
        },

        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        hasMoreItems() {
            return this.purchases.length < this.summary.totalCount;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            if (this.page == 1) this.init();
            else this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.loading = false;

                this.orders = [];
                this.purchases = [];
                this.summary = { totalCount: 0 };

                this.search();
            } catch (error) {
                this.$handleError(error);
            }
        },

        async search() {
            if (this.loading) return;
            this.loading = true;

            try {
                let { headers, params } = this;
                let { summary, purchases } = await api.v1.me.purchases.gets({
                    headers,
                    params,
                });

                this.summary = summary;
                let orders = purchases.reduce((orders, { order }) => (orders.some((item) => item._id == order._id) ? orders : [...orders, order]), []);
                for (const order of orders) {
                    this.updateOrder(order);
                }
                this.purchases = this.purchases.concat(purchases);
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.$nextTick(() => (this.loading = false));
            }
        },

        async updateOrder(order) {
            let index = this.orders.findIndex(({ _id }) => _id == order._id);
            if (0 <= index) this.orders.splice(index, 1, order);
            else this.orders.push(order);
        },

        loadMore() {
            if (this.loading) return;
            let { page = "1", ...query } = this.$route.query;
            query.page = +page + 1;
            this.$router.replace({ query });
        },
        loadInfinite(entries, observer, isIntersecting) {
            if (isIntersecting) this.loadMore();
        },
    },
};
</script>
