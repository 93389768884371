<template>
    <div>
        <tit-wrap-line noDivider title="찜한 상품">
            <v-btn outlined color="grey" to="/mypage/likes" class="v-size--xx-small">
                <span class="red--text">{{ summary.totalCount }}</span> 개 전체 보기
            </v-btn>
        </tit-wrap-line>

        <div class="border-t border-2 border-dark">
            <like-product-item v-for="item in items" :key="item._id" v-bind="{ item }" hideActions />

            <v-fade-transition leave-absolute>
                <v-sheet v-show="!items?.length" class="text-center">
                    <div class="txt txt--sm text-center py-20px py-md-30px">
                        찜한 상품이 없습니다
                    </div>
                    <v-divider />
                </v-sheet>
            </v-fade-transition>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import LikeProductItem from "../likes/like-product-item.vue";

export default {
    components: {
        TitWrapLine,
        LikeProductItem,
    },
    data: () => ({
        likes: [],

        limit: 3,
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        items() {
            return [...this.likes].map((item, index) => ({ index: this.summary.totalCount - index, ...item }));
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.loading = true;
            try {
                let { limit } = this;
                var { summary, likes } = await api.v1.me.likes.gets({
                    headers: { limit },
                    params: { withPopulate: true },
                });

                this.summary = summary;
                this.likes = likes;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
