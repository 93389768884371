<template>
    <v-dialog v-model="shows" persistent width="560" maxWidth="100%">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }" class="d-flex flex-column">
            <v-card-title>
                {{ title }} 철회하기 <v-spacer />
                <v-icon @click="close">mdi-close</v-icon>
            </v-card-title>
            <v-card-subtitle> 상품주문번호: {{ form?.purchaseNo }} | 상품주문일자 : {{ form?.createdAt?.toDate?.() }} </v-card-subtitle>
            <v-divider />
            <v-card-text class="pb-0">
                <purchase-item :value="form" mode="view" withQuantity />
                <v-divider />

                <!-- S:유형 -->
                <v-card-subtitle class="px-0 font-weight-bold">{{ typeText }} 유형</v-card-subtitle>
                <v-select v-model="form.claimReason" :placeholder="`${title} 유형을 선택해주세요`" :items="claimReasonItems" v-bind="{ ...attrs_input, loading }" readonly />
                <!-- E:유형 -->

                <!-- S:사유 -->
                <v-card-subtitle class="px-0 font-weight-bold">{{ typeText }} 사유</v-card-subtitle>
                <v-textarea v-model="form.claimReasonDetails" :placeholder="`${title} 사유를 입력해주세요`" v-bind="{ ...attrs_input, loading }" readonly />
                <!-- E:사유 -->
            </v-card-text>
            <v-card-actions class="pa-3">
                <v-col>
                    <v-btn outlined x-large block @click="close"> 취소 </v-btn>
                </v-col>
                <v-col>
                    <v-btn x-large block color="primary" @click="save"> {{ title }} 철회 </v-btn>
                </v-col>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, initPurchase, PURCHASE_CLAIM_STATUSES } from "@/assets/variables";

import PurchaseItem from "./purchase-item.vue";

export default {
    components: {
        PurchaseItem,
    },
    props: {
        value: { type: Object, default: initPurchase }, // purchases
        type: { type: String, default: PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value },
    },
    data: () => ({
        form: initPurchase(),

        // ui
        shows: false,
        loading: false,

        attrs_input,
    }),
    computed: {
        typeText() {
            return {
                [PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value]: "취소",
                [PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value]: "반품",
                [PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value]: "교환",
            }[this.type];
        },
        title() {
            return {
                [PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value]: "취소",
                [PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value]: "교환·반품",
                [PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value]: "교환·반품",
            }[this.type];
        },
        claimStatus() {
            return this.type;
        },
        claimReasonItems() {
            let items = [];

            items.push("단순변심");
            if (this.type != PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value) items.push("상품불량");
            items.push("기타");

            return items;
        },
    },
    watch: {
        shows(shows) {
            if (shows) this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initPurchase({ ...this.value });
        },

        emit() {
            this.$emit("input", this.form);
        },

        close() {
            this.shows = false;
        },

        async save() {
            if (this.loading) return;
            this.loading = true;

            try {
                let { _id } = this.form;
                let form = {
                    _id,
                    claimStatus: null,
                    claimReason: null,
                    claimReasonDetails: null,
                };
                this.form = (await api.v1.me.purchases.put(form))?.purchase;
                this.emit();
                alert(`해당 상품주문에 대한 "${this.typeText}" 요청이 철회되었습니다.`);
                this.close();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
