<template>
    <div class="pb-16px pb-md-20px">
        <v-row align="center" class="row--xs">
            <v-col cols="auto">
                <v-simple-checkbox :value="isAllSelected" hide-details @click="selectAll" />
            </v-col>
            <v-col cols="auto">
                <v-btn text class="px-10 mt-n2 mb-n1 ml-n9" @click="selectAll"> 전체선택 </v-btn>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
                <v-btn small outlined color="grey" :disabled="!value.length" @click="$emit('removeSelected')">
                    <span><v-icon>mdi-close</v-icon>선택삭제</span>
                </v-btn>
            </v-col>
            <!-- <v-col cols="auto">
                <v-btn small outlined color="grey" :disabled="!carts__outOfStock.length" @click="$emit('removeDisabled')">
                    <span><v-icon>mdi-close</v-icon>주문불가삭제</span>
                </v-btn>
            </v-col> -->
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: Array, default: () => [] }, // selected
        regularOrders: { type: Array, default: () => [] },
        carts__outOfStock: { type: Array, default: () => [] },
    },
    computed: {
        isAllSelected() {
            return !this.regularOrders.some(({ _id }) => !this.value.some((item) => item._id == _id));
        },
    },
    methods: {
        selectAll() {
            if (this.isAllSelected) this.$emit("input", []);
            else this.$emit("input", [...this.regularOrders]);
        },
    },
};
</script>

<style></style>
