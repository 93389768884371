<template>
    <v-row align="center" justify="center" justify-xl="space-between" class="row--sm">
        <v-col cols="auto">
            <v-row no-gutters align="center" justify="center" justify-md="space-between" class="w-md-500px">
                <v-col>
                    <v-row class="row--xxs text-center">
                        <v-col cols="12">
                            <div class="txt txt--xs">선택상품금액</div>
                        </v-col>
                        <v-col cols="12">
                            <strong class="d-inline-flex align-center font-size-18 font-size-md-20"> {{ productPrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="auto"> <v-icon color="grey lighten-3">mdi-plus</v-icon> </v-col>
                <v-col>
                    <v-row class="row--xxs text-center">
                        <v-col cols="12">
                            <div class="txt txt--xs">배송비</div>
                        </v-col>
                        <v-col cols="12">
                            <strong class="d-inline-flex align-center font-size-18 font-size-md-20"> {{ deliveryPrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="auto"> <v-icon color="grey lighten-3">mdi-minus</v-icon> </v-col>
                <v-col>
                    <v-row class="row--xxs text-center">
                        <v-col cols="12">
                            <div class="txt txt--xs">할인예상금액</div>
                        </v-col>
                        <v-col cols="12">
                            <strong class="d-inline-flex align-center font-size-18 font-size-md-20 red--text"> {{ discountPrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" xl="auto" class="align-self-stretch">
            <v-divider vertical class="d-none d-xl-flex h-100" />
        </v-col>

        <v-col cols="auto">
            <div class="pr-10px pr-xl-0">
                <v-row align="center" class="row--sm text-center">
                    <v-col cols="12" xl="auto">
                        <div class="txt txt--xs mb-n4px mb-xl-0">주문금액</div>
                    </v-col>
                    <v-col cols="12" xl="auto">
                        <strong class="d-inline-flex align-center font-size-18 font-size-md-20 primary--text mt-n10px"> {{ totalPrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                    </v-col>
                </v-row>
            </div>
        </v-col>
        <v-col cols="auto">
            <v-btn x-large color="primary" class="v-size--xx-large w-100 min-w-sm-200px" @click="$emit('order', selected)">{{ regular ? "정기배송 신청하기": "선택 주문하기" }}</v-btn>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
    props: {
        selected: { type: Array, default: () => [] },
        regular: { type: Boolean, default: false }
    },
    computed: {
        ...mapState(["shippings"]),
        cartMaps() {
            return this.$getCartMaps(this.selected);
        },
        productPrice() {
            return this.$getProductPrice(this.selected);
        },
        deliveryPrice() {
            return this.$getDeliveryPrice(this.selected, this.shippings);
        },
        discountPrice() {
            return this.$getDiscountPrice(this.selected);
        },
        totalPrice() {
            return this.productPrice + this.deliveryPrice - this.discountPrice;
        },
    },
};
</script>

<style></style>
