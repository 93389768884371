<template>
    <v-dialog width="100%" max-width="400" content-class="rounded-0">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, shows }" />
        </template>
        <v-card tile>
            <v-card-title>클레임정보</v-card-title>
            <vertical-form-table :value="purchase" v-bind="{ items }" mode="view" />
        </v-card>
    </v-dialog>
</template>

<script>
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

export default {
    components: {
        VerticalFormTable,
    },
    props: {
        purchase: { type: Object, default: () => {} },
    },
    data: () => ({}),
    computed: {
        shows() {
            return !!this.purchase?.claimStatus;
        },
        items() {
            const items = [];

            items.push({ type: "text", term: "사유", key: "claimReason" });
            items.push({ type: "text", term: "상세", key: "claimReasonDetails" });
            if (this.purchase?.feedbackMessage) items.push({ type: "text", term: "답변", key: "feedbackMessage" });
            items.push({ type: "text", term: "상태", key: "claimStatusMessage" });
            if (this.purchase?.rejectedAt) items.push({ type: "datetime", term: "거부일시", key: "rejectedAt" });
            if (this.purchase?.canceledAt) items.push({ type: "datetime", term: "취소일시", key: "canceledAt" });
            if (this.purchase?.returnedAt) items.push({ type: "datetime", term: "반품일시", key: "returnedAt" });
            if (this.purchase?.exchangeShippedAt) items.push({ type: "datetime", term: "재배송일시", key: "exchangeShippedAt" });
            if (this.purchase?.exchangedAt) items.push({ type: "datetime", term: "교환일시", key: "exchangedAt" });

            return items.map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), cellClass: "caption", divider: index != array.length - 1 }));
        },
    },
};
</script>
