var render = function render(){
  var _vm$value;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "title": "배송지 등록하기",
      "width": "560"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--sm"
        }, [_c('v-col', [_c('v-btn', {
          staticClass: "v-size--xx-large",
          attrs: {
            "block": "",
            "outlined": "",
            "color": "grey"
          },
          on: {
            "click": function ($event) {
              _vm.shows = false;
            }
          }
        }, [_vm._v("취소")])], 1), _c('v-col', [_c('v-btn', {
          staticClass: "v-size--xx-large",
          attrs: {
            "block": "",
            "color": "primary"
          },
          on: {
            "click": _vm.save
          }
        }, [_vm._v(_vm._s(_vm.isCreate ? "생성" : "수정"))])], 1)], 1)];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('tit-form-section', _vm._b({
    scopedSlots: _vm._u([{
      key: "주소",
      fn: function () {
        return [_c('v-address-field', _vm._b({
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v;
            },
            expression: "form"
          }
        }, 'v-address-field', _vm.attrs_input, false))];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'tit-form-section', {
    items: _vm.items
  }, false)), !((_vm$value = _vm.value) !== null && _vm$value !== void 0 && _vm$value.isDefault) ? [_c('page-section', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('v-checkbox', {
          attrs: {
            "label": "기본 배송지로 설정",
            "hide-details": "auto"
          },
          model: {
            value: _vm.form.isDefault,
            callback: function ($$v) {
              _vm.$set(_vm.form, "isDefault", $$v);
            },
            expression: "form.isDefault"
          }
        })];
      },
      proxy: true
    }], null, false, 2033055497)
  })] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }