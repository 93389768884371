<template>
    <u-dialog v-model="shows" title="간편결제등록완료" persistent width="400">
        <div class="txt txt--xs txt--dark font-weight-medium text-center py-20px py-md-0">간편결제 등록이 완료되었습니다.</div>

        <div class="btn-wrap">
            <v-row class="row--sm">
                <v-col>
                    <v-btn v-if="type === 'cart'" block color="primary" class="v-size--xx-large" @click="next">정기배송 신청하기</v-btn>
                    <v-btn v-if="type === 'card'" block color="primary" class="v-size--xx-large" @click="close">확인</v-btn>
                </v-col>
            </v-row>
        </div>
    </u-dialog>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import ProductItem from "../product/product-item.vue";
import TitWrapSmall from "@/components/client/dumb/tit-wrap-small.vue";
import VerticalTable from "@/components/dumb/vertical-table.vue";
import ShopProductCarts from "../../shop/shop-product-carts.vue";
import ShopProductOptions from "../../shop/shop-product-options.vue";
import ShopProductSupplies from "../../shop/shop-product-supplies.vue";

export default {
    components: {
        PageSection,
        UDialog,
        ProductItem,
        TitWrapSmall,
        VerticalTable,
        ShopProductCarts,
        ShopProductOptions,
        ShopProductSupplies,
    },
    data() {
        return {
            shows: false,
            type: null,
        };
    },
    methods: {
        open(type) {
            this.type = type;
            this.shows = true;
        },
        close() {
            this.shows = false;
        },
        next() {
            this.shows = false;
            this.$emit("next");
        },
    },
};
</script>
