<template>
    <v-sheet>
        <div class="pb-16px pt-md-10px pb-md-30px">
            <div class="py-16px py-md-20px">
                <v-row align-md="center" class="ma-n8px">
                    <v-col>
                        <list-item-purchase v-bind="value" />
                    </v-col>
                    <v-col align-self="start" cols="12" md="auto" class="pa-8px">
                        <v-row align="center" class="row--xs">
                            <v-col cols="auto">
                                <span class="txt txt--xs txt--light">{{ value.createdAt.toDate() }}</span>
                            </v-col>
                            <v-col cols="auto">
                                <v-row class="row--xxs" justify="center">
                                    <v-col cols="4" md="auto" class="mw-100 flex-grow-1 flex-md-grow-0 flex-shrink-0">
                                        <v-btn v-bind="{ ...btn_tertiary, ...$attrs }" block class="v-size--xx-small" @click="remove">삭제</v-btn>
                                    </v-col>
                                    <v-col cols="4" md="auto" class="mw-100 flex-grow-1 flex-md-grow-0 flex-shrink-0">
                                        <review-form v-bind="{ value, purchase: value.purchase }" @init="$emit('search')">
                                            <template #activator="{ attrs, on }">
                                                <v-btn v-bind="{ ...btn_tertiary, ...attrs }" v-on="on" block class="v-size--xx-small"> 수정 </v-btn>
                                            </template>
                                        </review-form>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>

            <v-card outlined class="border-dark">
                <div class="pa-16px pa-md-20px">
                    <v-row>
                        <v-col cols="12" md="auto" order-md="2" v-if="src">
                            <v-card>
                                <v-img v-bind="{ src }" width="64" height="64" />
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="" order-md="1">
                            <div class="mb-4px mb-md-8px">
                                <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="value.star" />
                            </div>
                            <div class="txt txt--xs" style="white-space: pre-line">
                                {{ value.content }}
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </div>
        <v-divider />
    </v-sheet>
</template>

<script>
import api from "@/api";

import { initShopReview } from "@/assets/variables";
import { btn_tertiary } from "@/assets/variables";

import ReviewForm from "@/components/client/shop/reviews/review-form.vue";
import ListItemPurchase from "./list-item-purchase.vue";

export default {
    components: {
        ReviewForm,
        ListItemPurchase,
    },
    props: {
        value: { type: Object, default: initShopReview },
    },
    computed: {
        src() {
            return this.value?.images?.[0]?.url;
        },
    },
    data: () => ({
        btn_tertiary,

        loading: false,
    }),
    methods: {
        async remove() {
            const go = confirm("해당 구매후기를 삭제하시겠습니까?");
            if (!go) return;

            if (this.loading) return;
            else this.loading = true;
            try {
                await api.v1.me.reviews.delete(this.value);
                alert("삭제되었습니다");
                this.$emit("search");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
